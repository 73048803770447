import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import axios from '../fetchWithCsrf';
import Visualization from '../components/Visualization';
import './Dashboards.css';
import API_BASE_URL from "../config";

const Dashboards = () => {
    const [dashboards, setDashboards] = useState([]);
    const [elements, setElements] = useState([]);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [renameId, setRenameId] = useState(null);
    const [renameType, setRenameType] = useState('');
    const [newName, setNewName] = useState('');
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteType, setDeleteType] = useState('');
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [newDashboardName, setNewDashboardName] = useState('New Dashboard');
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${API_BASE_URL}/get-dashboards/`, {
            withCredentials: true
        })
        .then((response) => {
            const data = response.data;
            if (Array.isArray(data)) {
                setDashboards(data);
            }
        })
        .catch((error) => console.error('Error fetching dashboards:', error));

        axios.get(`${API_BASE_URL}/get-elements/`, {
            withCredentials: true
        })
        .then((response) => {
            const data = response.data;
            if (Array.isArray(data)) {
                setElements(data);
            }
        })
        .catch((error) => console.error('Error fetching elements:', error));
    }, []);

    const handleDashboardClick = (dashboard) => {
        navigate(`/edit-dashboard/${dashboard.id}`);
    };

    const handleElementClick = (element) => {
        navigate(`/element/${element.id}`);
    };

    const handleCreateNewDashboard = async () => {
        console.log('Attempting to create a new dashboard with name:', newDashboardName);  // Debug: log dashboard name

        try {
            const response = await axios.post(
                `${API_BASE_URL}/create-new-dashboard/`,
                { name: newDashboardName },
                { withCredentials: true }
            );

            console.log('Response from creating new dashboard:', response);  // Debug: log the full response object
            const newDashboardId = response.data.id;
            console.log('New dashboard ID:', newDashboardId);  // Debug: log new dashboard ID

            navigate(`/edit-dashboard/${newDashboardId}`);
        } catch (error) {
            console.error('Error creating new dashboard:', error);  // Log the error if request fails
            console.log('Error details:', error.response ? error.response.data : 'No response data');  // Debug: log response data from error if available
        }

        setIsCreateModalOpen(false);  // Close the modal after attempting to create the dashboard
        console.log('Closed the create dashboard modal');  // Debug: confirm modal closure
    };

    const openCreateModal = () => {
        setNewDashboardName('New Dashboard');  // Reset the name field to default
        setIsCreateModalOpen(true);
    };

    const closeCreateModal = () => {
        setIsCreateModalOpen(false);
    };

    const openRenameModal = (id, currentName, type) => {
        setRenameId(id);
        setNewName(currentName);
        setRenameType(type);
        setIsRenameModalOpen(true);
    };

    const closeRenameModal = () => {
        setIsRenameModalOpen(false);
        setRenameId(null);
        setNewName('');
        setRenameType('');
    };

    const handleRename = async () => {
        try {
            const url = renameType === 'dashboard'
                ? `${API_BASE_URL}/rename-dashboard/${renameId}/`
                : `${API_BASE_URL}/rename-element/${renameId}/`;

            await axios.patch(url, { name: newName }, { withCredentials: true });

            if (renameType === 'dashboard') {
                setDashboards(prevDashboards =>
                    prevDashboards.map(db => db.id === renameId ? { ...db, name: newName } : db)
                );
            } else {
                setElements(prevElements =>
                    prevElements.map(el => el.id === renameId ? { ...el, name: newName } : el)
                );
            }

            closeRenameModal();
        } catch (error) {
            console.error(`Error renaming ${renameType}:`, error);
        }
    };

    const openConfirmModal = (id, type) => {
        setDeleteId(id);
        setDeleteType(type);
        setIsConfirmModalOpen(true);
    };

    const closeConfirmModal = () => {
        setIsConfirmModalOpen(false);
        setDeleteId(null);
        setDeleteType('');
    };

    // New Duplicate function for dashboards and elements
    const handleDuplicate = async (id, type) => {
        try {
            const url = type === 'dashboard'
                ? `${API_BASE_URL}/duplicate-dashboard/${id}/`
                : `${API_BASE_URL}/duplicate-element/${id}/`;

            const response = await axios.post(url, {}, { withCredentials: true });

            // Update the state with the newly duplicated dashboard or element
            if (type === 'dashboard') {
                setDashboards(prevDashboards => [...prevDashboards, response.data.new_dashboard]);
            } else {
                setElements(prevElements => [...prevElements, response.data.new_element]);
            }
        } catch (error) {
            console.error(`Error duplicating ${type}:`, error);
        }
    };


    const handleDelete = async () => {
        try {
            const url = deleteType === 'dashboard'
                ? `${API_BASE_URL}/delete-dashboard/${deleteId}/`
                : `${API_BASE_URL}/delete-element/${deleteId}/`;

            await axios.delete(url, { withCredentials: true });

            if (deleteType === 'dashboard') {
                setDashboards(prevDashboards => prevDashboards.filter(db => db.id !== deleteId));
            } else {
                setElements(prevElements => prevElements.filter(el => el.id !== deleteId));
            }

            closeConfirmModal();
        } catch (error) {
            console.error(`Error deleting ${deleteType}:`, error);
        }
    };

    return (
        <div className="dashboards-page">
            <div className="dashboard-header">
                <h1>Dashboards</h1>
                <button className="dashboard-create-new-button" onClick={openCreateModal}>
                    Create New +
                </button>
            </div>
            <div className="dashboard-container">
                {dashboards.map(dashboard => (
                    <div
                        key={dashboard.id}
                        className="dashboard-item"
                    >
                        <div className="container-dashboard-header">
                            <h3 className="dashboard-name"
                                onClick={() => handleDashboardClick(dashboard)}>{dashboard.name}</h3>
                            <div className="dashboard-icons">
                                <img
                                    src="/icons/Duplicate-icon.png"
                                    alt="Duplicate"
                                    className="icon dashboard-duplicate-icon"
                                    title="Duplicate"  // Hover text
                                    onClick={() => handleDuplicate(dashboard.id, 'dashboard')}
                                />
                                <img
                                    src="/icons/rename-icon.png"
                                    alt="Rename"
                                    className="icon dashboard-rename-icon"
                                    title="Rename"  // Hover text
                                    onClick={() => openRenameModal(dashboard.id, dashboard.name, 'dashboard')}
                                />
                                <img
                                    src="/icons/Trash-icon.png"
                                    alt="Delete"
                                    className="icon dashboard-delete-icon"
                                    title="Delete"  // Hover text
                                    onClick={() => openConfirmModal(dashboard.id, 'dashboard')}
                                />
                            </div>
                        </div>
                        <div className="dashboard-thumbnail" onClick={() => handleDashboardClick(dashboard)}>
                            {dashboard.elements.length > 0 ? (
                                <Visualization
                                    key={dashboard.elements[0].id}
                                    config={{
                                        ...dashboard.elements[0].chart_config_override || dashboard.elements[0].chart_config,
                                        showDataLabels: false,  // Disable data labels for the thumbnails
                                    }}
                                />
                            ) : (
                                <p>No elements added</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <h2>Elements</h2>
            <div className="elements-container">
                {elements
                    .filter(element => element.type === 'chart' && element.chart_config) // Ensure only charts with config are shown
                    .map(element => (
                        <div
                            key={element.id}
                            className="element-item"
                        >
                            <div className="element-header">
                                <h3 className="element-name" onClick={() => handleElementClick(element)}>
                                    {element.name || 'Unnamed Chart'}
                                </h3>
                                <div className="element-icons">
                                    <img
                                        src="/icons/Duplicate-icon.png"
                                        alt="Duplicate"
                                        className="icon elements-duplicate-icon"
                                        title="Duplicate"  // Hover text
                                        onClick={() => handleDuplicate(element.id, 'element')}
                                    />
                                    <img
                                        src="/icons/rename-icon.png"
                                        alt="Rename"
                                        className="icon elements-rename-icon"
                                        title="Rename"  // Hover text
                                        onClick={() => openRenameModal(element.id, element.name, 'element')}
                                    />
                                    <img
                                        src="/icons/Trash-icon.png"
                                        alt="Delete"
                                        className="icon elements-delete-icon"
                                        title="Delete"  // Hover text
                                        onClick={() => openConfirmModal(element.id, 'element')}
                                    />
                                </div>
                            </div>
                            <div className="element-thumbnail" onClick={() => handleElementClick(element)}>
                                <Visualization
                                    config={{
                                        ...element.chart_config, // Show only charts with a valid config
                                        showDataLabels: false,  // Disable data labels for the thumbnails
                                    }}
                                />
                            </div>
                        </div>
                    ))}
            </div>

            {/* Create New Dashboard Modal */}
            <ReactModal
                isOpen={isCreateModalOpen}
                onRequestClose={closeCreateModal}
                className="dashboard-create-modal-content"
                overlayClassName="dashboard-create-modal-overlay"
            >
                <h2 className="dashboard-create-modal-title">Name Your Dashboard</h2>
                <input
                    type="text"
                    value={newDashboardName}
                    onChange={(e) => setNewDashboardName(e.target.value)}
                    className="dashboard-create-modal-input"
                />
                <div className="dashboard-create-modal-buttons">
                    <button
                        onClick={closeCreateModal}
                        className="dashboard-create-cancel-button"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleCreateNewDashboard}
                        className="dashboard-create-confirm-button"
                    >
                        Create Dashboard
                    </button>
                </div>
            </ReactModal>

            {/* Rename Modal */}
            <ReactModal isOpen={isRenameModalOpen} onRequestClose={closeRenameModal} className="rename-modal"
                        overlayClassName="confirm-modal-overlay">
                <h2>Rename {renameType === 'dashboard' ? 'Dashboard' : 'Element'}</h2>
                <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)}/>
                <div className="modal-buttons">
                    <button onClick={closeRenameModal} className="cancel-button">Cancel</button>
                    <button onClick={handleRename} className="confirm-rename-button">Rename</button>
                </div>
            </ReactModal>

            {/* Delete Confirmation Modal */}
            <ReactModal
                isOpen={isConfirmModalOpen}
                onRequestClose={closeConfirmModal}
                className="confirm-modal"
                overlayClassName="confirm-modal-overlay"
            >
                <h2>Confirm Deletion</h2>
                <div style={{maxWidth: '400px', margin: '0 auto', textAlign: 'center'}}>
                    {deleteType === 'element' ? (
                        <p>Are you sure you want to delete this Element? This Element will also be deleted from
                            Dashboards.</p>
                    ) : (
                        <p>Are you sure you want to delete this Dashboard?</p>
                    )}
                </div>
                <div className="modal-buttons">
                    <button onClick={closeConfirmModal} className="cancel-button">Cancel</button>
                    <button onClick={handleDelete} className="confirm-delete-button">Delete</button>
                </div>
            </ReactModal>

        </div>
    );
};

export default Dashboards;
