import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../fetchWithCsrf'; // Import the configured Axios instance
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'; // Import Firebase Auth
import { useAuth } from '../AuthContext'; // Import the AuthContext to manage authentication state
import API_BASE_URL from "../config";

const auth = getAuth();

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setAuth } = useAuth(); // Use the AuthContext to manage authentication state

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Firebase Auth sign-in
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const idToken = await user.getIdToken(); // Get ID token

      // Log the ID token to verify it's being retrieved correctly
      console.log('ID Token:', idToken);

      // Prepare the payload with the email and password
      const payload = {
        email: email,
        idToken: idToken, // Ensure the ID token is included in the payload
      };

      // Send the ID token and credentials to the backend
      const response = await axios.post(`${API_BASE_URL}/login/`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      // Log the response from the backend to verify it
      console.log('Backend Response:', response);

      if (response.status === 200) {
        setAuth(true, idToken); // Update authentication state
        const data = response.data;
        if (data.first_login) {
          navigate('/password_change');
        } else {
          navigate('/home');
        }
      } else {
        setError(response.data.error);
        console.log('Login failed:', response.data.error);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Email or password is invalid.');
    }
  };

  return (
    <div className="login-container">
      <div className="logo">
        <img src="/icons/LogoNoBackground.png" alt="Spruce Logo" />
        <h1>Spruce</h1>
      </div>
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Sign in</h2>
        {error && <div className="error-message">{error}</div>}
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
        </div>
        <button type="submit" className="login-button">Continue</button>
        <div className="forgot-password">
          <a href="/forgot-password">Forgot password?</a>
        </div>
      </form>
    </div>
  );
};

export default Login;
