import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TableVisualization from './TableVisualization';

const Visualization = ({ config, requestedChartType, onSegmentClick, onLabelChange }) => {
    const canvasRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (!config) {
            console.warn("No config found.");
            return;
        }

        // Check if it's a table configuration and skip chart rendering logic
        if (config.type === 'table') {
            console.log("Rendering table visualization with config:", config);
            return;
        }

        if (!config.data) {
            console.warn("No config.data found.");
            return;
        }

        const { data, options = {} } = config;

        // Apply default borderWidth to bar, pie, and line charts
        data.datasets = data.datasets.map((dataset) => {
            if (['bar', 'pie', 'line'].includes(dataset.type)) {
                return {
                    ...dataset,
                    borderWidth: dataset.borderWidth || 1,
                };
            }
            return dataset;
        });

        // Log the initial config for debugging
        console.log("Initial config passed to Visualization:", config);
        console.log("Requested chart type:", requestedChartType);

        // Customize legend with dynamic dataset colors
        const chartOptions = {
            ...options,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                ...options?.plugins,
                datalabels: {
                    display: config?.showDataLabels || false,
                    align: config?.dataLabelOffset < 0 ? 'start' : 'end',
                    anchor: config?.dataLabelOffset < 0 ? 'end' : 'start',
                    offset: Math.abs(config?.dataLabelOffset || 0),
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    color: 'black',
                    font: {
                        size: 12,
                    },
                    formatter: function (value) {
                        return '$' + value.toLocaleString();
                    },
                },
                legend: {
                    display: options?.plugins?.legend?.display || false,
                    labels: {
                        generateLabels: function (chart) {
                            return chart.data.datasets.map((dataset, i) => {
                                let legendItem = {
                                    text: dataset?.label || `Dataset ${i}`,
                                    hidden: !chart.isDatasetVisible(i),
                                    index: i
                                };

                                if (dataset.type === 'bar') {
                                    legendItem.fillStyle = Array.isArray(dataset?.backgroundColor) ? dataset?.backgroundColor[0] : dataset?.backgroundColor || 'transparent';
                                    legendItem.strokeStyle = Array.isArray(dataset?.borderColor) ? dataset?.borderColor[0] : dataset?.borderColor || 'transparent';
                                } else if (dataset.type === 'line') {
                                    legendItem.fillStyle = Array.isArray(dataset?.pointBackgroundColor) ? dataset?.pointBackgroundColor[0] : dataset?.pointBackgroundColor || 'transparent';
                                    legendItem.strokeStyle = Array.isArray(dataset?.pointBorderColor) ? dataset?.pointBorderColor[0] : dataset?.pointBorderColor || 'transparent';
                                }

                                return legendItem;
                            });
                        }
                    },
                    onClick: function (e, legendItem) {
                        const index = legendItem.index;
                        const chart = this.chart;
                        chart.data.datasets[index].hidden = !chart.data.datasets[index].hidden;
                        chart.update();
                    }
                },

                title: {
                    display: !!(options?.plugins?.title?.text),
                    text: options?.plugins?.title?.text || 'Default Title',
                },
            },
            scales: {
                x: {
                    ...options?.scales?.x,
                    title: {
                        display: !!(options?.scales?.x?.title?.text),
                        text: options?.scales?.x?.title?.text || 'X-Axis Label',
                    },
                },
                y: {
                    ...options?.scales?.y,
                    title: {
                        display: !!(options?.scales?.y?.title?.text),
                        text: options?.scales?.y?.title?.text || 'Y-Axis Label',
                    },
                },
            },
        };

        const ctx = canvasRef.current.getContext('2d');

        // Destroy previous chart if it exists to avoid memory leaks
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        // Determine the chart type based on the user's requested chart type
        let chartType = config?.type || 'bar';

        // If the user requested an area chart, treat it as 'line' and apply 'fill: true'
        if (requestedChartType === 'Area') {
            chartType = 'line';
            data.datasets = data.datasets.map((dataset) => ({
                ...dataset,
                fill: true,
            }));
            console.log("Area chart requested. Treating as line chart with fill.");
        }

        if (requestedChartType === 'Bar') {
            chartOptions.scales.x.stacked = false;
            chartOptions.scales.y.stacked = false;
            console.log("Bar chart requested. Ensuring bars are not stacked.");
        }

        if (requestedChartType === 'Line') {
            data.datasets = data.datasets.map((dataset) => ({
                ...dataset,
                fill: false,
            }));
            console.log("Line chart requested. Ensuring lines are not filled.");
        }

        // If the user requested a stacked bar chart, apply stacking logic
        if (requestedChartType === 'Stacked Bar') {
            chartType = 'bar';
            chartOptions.scales.x.stacked = true;
            chartOptions.scales.y.stacked = true;
            console.log("Stacked bar chart requested. Enforcing stacked bars.");
        }


        // Handle "Bar Line Combo" chart type and ensure line is on top of the bar
        if (requestedChartType === 'Bar Line Combo') {
            chartType = 'bar';  // Treat as a bar chart in Chart.js
            chartOptions.scales.x.stacked = false;  // Ensure bars and lines are not stacked
            chartOptions.scales.y.stacked = false;

            // Assign types and set order to ensure lines are drawn on top
            data.datasets = data.datasets.map((dataset, index) => ({
                ...dataset,
                order: dataset.type === 'line' ? 1 : 2, // Higher order for lines
                fill: false
            }));
            console.log("Bar Line Combo chart requested. Respecting independent dataset types.");
        }

        // Log the final chart type, data, and options being sent to Chart.js
        console.log("Final chartType:", chartType);
        console.log("Final data passed to Chart.js:", data);
        console.log("Final chartOptions passed to Chart.js:", chartOptions);

        try {
            chartInstanceRef.current = new Chart(ctx, {
                type: chartType,
                data: {
                    ...data,
                    datasets: data.datasets.map(dataset => ({
                        ...dataset,
                        type: dataset.type || chartType,  // Respect dataset-specific types
                    })),
                },
                options: {
                    ...chartOptions,
                    onClick: (event, elements) => {
                        if (elements.length > 0 && typeof onSegmentClick === 'function') {
                            const clickedElementIndex = elements[0].index;
                            const datasetIndex = elements[0].datasetIndex;
                            const dataset = data.datasets[datasetIndex];
                            const segmentColor = dataset?.backgroundColor?.[clickedElementIndex];
                            const segmentBorderColor = dataset?.borderColor ? dataset.borderColor[clickedElementIndex] : null;
                            const dotColor = dataset?.pointBackgroundColor ? dataset.pointBackgroundColor[clickedElementIndex] : null;
                            const dotBorderColor = dataset?.pointBorderColor ? dataset.pointBorderColor[clickedElementIndex] : null;

                            onSegmentClick({
                                index: clickedElementIndex,
                                datasetIndex,
                                currentColor: segmentColor,
                                currentBorderColor: segmentBorderColor,
                                dotColor: dotColor,
                                dotBorderColor: dotBorderColor,
                            });
                        }
                    },
                    onHover: (event, elements) => {
                        event.native.target.style.cursor = elements.length > 0 ? 'pointer' : 'default';
                    },
                },
                plugins: [ChartDataLabels],
            });

            chartInstanceRef.current.update();
        } catch (error) {
            console.error('Error rendering chart:', error);
        }

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [config, requestedChartType, onSegmentClick, onLabelChange]);

    // Conditionally render the table or chart
    if (config?.type === 'table') {
        return <TableVisualization config={config} />;
    }

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <canvas ref={canvasRef}></canvas>
        </div>
    );
};

export default Visualization;
