import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Dashboards from './pages/Dashboards';
import DataSources from './pages/DataSources';
import Logout from './pages/Logout';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import PasswordChange from './pages/PasswordChange';
import ForgotPassword from './pages/ForgotPassword';
import ProtectedRoute from './ProtectedRoute';
import { useAuth } from './AuthContext';
import './App.css';
import MyDataSources from './pages/MyDataSources';
import Schema from './pages/Schema';
import EditElement from "./pages/EditElement";
import EditDashboard from "./pages/EditDashboard";
import Settings from './pages/Settings';
import ArchivedChats from './pages/ArchivedChats';
import UserAccessPage from "./pages/UserAccessPage";

const App = () => {
  const { isAuthenticated } = useAuth();

  console.log('App component rendered'); // Debug log to see how many times the component renders

  return (
    <Router>
      <div className="app">
        <ConditionalNavbar isAuthenticated={isAuthenticated} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/password_change" element={<PasswordChange />} />
          <Route path="/home" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Home /></ProtectedRoute>} />
          <Route path="/dashboards" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Dashboards /></ProtectedRoute>} />
          <Route path="/data-sources" element={<ProtectedRoute isAuthenticated={isAuthenticated}><DataSources /></ProtectedRoute>} />
          <Route path="/my-data-sources" element={<ProtectedRoute isAuthenticated={isAuthenticated}><MyDataSources /></ProtectedRoute>} />
          <Route path="/datasources/:id/schema" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Schema /></ProtectedRoute>} />
          <Route path="/element/:id" element={<ProtectedRoute isAuthenticated={isAuthenticated}><EditElement /></ProtectedRoute>} />
          <Route path="/edit-dashboard/:id" element={<ProtectedRoute isAuthenticated={isAuthenticated}><EditDashboard /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Settings /></ProtectedRoute>} />
          <Route path="/settings/archived-chats" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ArchivedChats /></ProtectedRoute>} />
          <Route path="/settings/user-access" element={<ProtectedRoute isAuthenticated={isAuthenticated}><UserAccessPage /></ProtectedRoute>} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
};

// Conditional rendering of the Navbar based on the route and authentication status
const ConditionalNavbar = React.memo(({ isAuthenticated }) => {
  const location = useLocation();
  const hideNavbarPaths = ['/login', '/signup', '/forgot-password', '/logout', '/password_change'];

  if (hideNavbarPaths.includes(location.pathname)) {
    return null;
  }

  return <Navbar isAuthenticated={isAuthenticated} />;
});

export default App;
