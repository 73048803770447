import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../fetchWithCsrf';
import './Schema.css';
import API_BASE_URL from "../config";

const Schema = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tableMetadata, setTableMetadata] = useState([]);
  const [columnMetadata, setColumnMetadata] = useState([]);
  const [dataSourceName, setDataSourceName] = useState('');
  const [expandedTable, setExpandedTable] = useState(null);
  const [expandedColumns, setExpandedColumns] = useState([]);
  const [viewMode, setViewMode] = useState('metadata');
  const [showTables, setShowTables] = useState(false);
  const [otherDataSources, setOtherDataSources] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dataPreview, setDataPreview] = useState([]);
  const [description, setDescription] = useState('');
  const dropdownRef = useRef(null);
  const [isDataPreviewLoading, setIsDataPreviewLoading] = useState(false);


  const handleDataPreviewClick = useCallback(async (tableId) => {
      if (!tableId) return;

      try {
          // Set loading state to true
          setIsDataPreviewLoading(true);

          const response = await axios.get(`${API_BASE_URL}/fetch_preview_data/?table_id=${tableId}&limit=10`, {
              withCredentials: true
          });
          setDataPreview(response.data);
      } catch (error) {
          console.error('Error fetching data preview:', error);
      } finally {
          // Set loading state to false
          setIsDataPreviewLoading(false);
      }
  }, []);


  const fetchTableMetadata = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/table_metadata/?data_source=${id}`, {
        withCredentials: true
      });
      setTableMetadata(response.data);
      if (response.data.length > 0) {
        const firstTableId = response.data[0].id;
        setExpandedTable(firstTableId);
        if (viewMode === 'dataPreview') {
          handleDataPreviewClick(firstTableId);
        }
      }
    } catch (error) {
      console.error('Error fetching table metadata:', error);
    }
  }, [id, viewMode, handleDataPreviewClick]);

  const fetchColumnMetadata = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/column_metadata/?data_source=${id}`, {
        withCredentials: true
      });
      setColumnMetadata(response.data);
    } catch (error) {
      console.error('Error fetching column metadata:', error);
    }
  }, [id]);

  const fetchDataSourceName = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/datasources/${id}/`, {
        withCredentials: true
      });
      setDataSourceName(response.data.name);
    } catch (error) {
      console.error('Error fetching data source name:', error);
    }
  }, [id]);

  const fetchOtherDataSources = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/datasources/`, {
        withCredentials: true
      });
      const dataSources = response.data.filter(dataSource => dataSource.id !== parseInt(id));
      setOtherDataSources(dataSources);
    } catch (error) {
      console.error('Error fetching other data sources:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchTableMetadata();
    fetchColumnMetadata();
    fetchDataSourceName();
    fetchOtherDataSources();
  }, [fetchTableMetadata, fetchColumnMetadata, fetchDataSourceName, fetchOtherDataSources]);

  useEffect(() => {
    if (expandedTable) {
      const table = tableMetadata.find(table => table.id === expandedTable);
      if (table) {
        setDescription(table.description);
      }
    }
  }, [expandedTable, tableMetadata]);

  const handleTableClick = (tableId) => {
    setExpandedTable(expandedTable === tableId ? null : tableId);
    if (viewMode === 'dataPreview') {
      handleDataPreviewClick(tableId);
    }
  };

  const handleColumnClick = (columnId) => {
    setExpandedColumns(prev =>
      prev.includes(columnId) ? prev.filter(id => id !== columnId) : [...prev, columnId]
    );
  };

  const handleDataSourceSwitch = (dataSourceId) => {
    setShowDropdown(false);
    navigate(`/datasources/${dataSourceId}/schema`);
    if (viewMode === 'dataPreview') {
      handleDataPreviewClick();
    }
  };

  const handleSaveDescription = async (columnId, description) => {
    try {
      const column = columnMetadata.find(col => col.id === columnId);
      if (column) {
        const updatedColumn = { ...column, description };

        await axios.put(`${API_BASE_URL}/column_metadata/${columnId}/`, updatedColumn, {
          withCredentials: true
        });

        setColumnMetadata(prevState =>
          prevState.map(col =>
            col.id === columnId ? { ...col, description } : col
          )
        );
      }
    } catch (error) {
      console.error('Error saving column description:', error);
    }
  };

  const handleSaveTableDescription = async (tableId, description) => {
    try {
      const table = tableMetadata.find(tbl => tbl.id === tableId);
      if (table) {
        const updatedTable = { ...table, description };

        await axios.put(`${API_BASE_URL}/table_metadata/${tableId}/`, updatedTable, {
          withCredentials: true
        });

        setTableMetadata(prevState =>
          prevState.map(tbl =>
            tbl.id === tableId ? { ...tbl, description } : tbl
          )
        );
      }
    } catch (error) {
      console.error('Error saving table description:', error);
    }
  };

  const renderTableColumns = () => {
    const columns = columnMetadata.filter(column => column.table_metadata === expandedTable);
    return (
      <table className="columns-table">
        <tbody>
          {columns.map((column) => (
            <React.Fragment key={column.id}>
              <tr className={expandedColumns.includes(column.id) ? 'expanded' : ''}>
                <td colSpan="2" className="expandable-row" onClick={() => handleColumnClick(column.id)}>
                  <div className="column-header">
                    {column.column_name}
                    <span className="column-type">{column.column_type}</span>
                    <span className={expandedColumns.includes(column.id) ? 'arrow down' : 'arrow right'}>▼</span>
                  </div>
                  {expandedColumns.includes(column.id) && (
                    <div className="description-container">
                      <label className="description-label">Description:</label>
                      <textarea
                        className="description-input"
                        defaultValue={column.description}
                        onClick={(e) => e.stopPropagation()}
                        onBlur={(e) => handleSaveDescription(column.id, e.target.value)}
                      ></textarea>
                    </div>
                  )}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  const renderDataPreview = () => {
    if (isDataPreviewLoading) {
        // Display the loading GIF
        return (
            <div className="render-loading-container">
              <img
                  src="/icons/Logo-loading.gif"
                  alt="Loading..."
                  className="preview-loading-gif"
              />
            </div>
        );
    }

    if (!dataPreview || dataPreview.length === 0) {
      return <p>No data available for preview.</p>;
    }

    const columns = Object.keys(dataPreview[0]);

    return (
      <div className="data-preview-container">
        <table className="data-preview-table">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataPreview.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>
                    {typeof row[col] === 'boolean' ? row[col].toString() : row[col]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="schema-page">
      <div className="data-sources-back-button-container">
        <button onClick={() => navigate('/my-data-sources')} className="data-sources-back-button">
          <img src="/icons/arrow-back.svg" alt="Back" className="data-sources-back-arrow"/> Back
        </button>
      </div>
      <div className="header-container">
        <div className="title-description-container" ref={dropdownRef} onClick={handleDropdownClick}>
          <div>
            <h2 className="datasource-title" onClick={() => setShowDropdown(!showDropdown)}>
              {dataSourceName} <span className="dropdown-arrow">▼</span>
            </h2>
            {showDropdown && (
              <div className="dropdown-menu">
                {otherDataSources.length > 0 ? (
                  otherDataSources.map((dataSource) => (
                    <div key={dataSource.id} onMouseDown={(e) => e.stopPropagation()} onClick={() => handleDataSourceSwitch(dataSource.id)}>
                      {dataSource.name}
                    </div>
                  ))
                ) : (
                  <div>No other data sources connected</div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="view-switcher">
          <button onClick={() => setViewMode('metadata')} className={viewMode === 'metadata' ? 'active' : ''}>Columns</button>
          <button onClick={() => {
            setViewMode('dataPreview');
            handleDataPreviewClick();
          }} className={viewMode === 'dataPreview' ? 'active' : ''}>Data Preview</button>
        </div>
      </div>
      {viewMode === 'dataPreview' && (
        <p className="data-preview-message">*Data preview displays 10 rows</p>
      )}
      <div className="schema-content">
        <div className="sidebar">
          <div className="tables-header" onClick={() => setShowTables(!showTables)}>
            <h3>Tables <span className="dropdown-arrow">▼</span></h3>
          </div>
          {showTables && (
            <ul>
              {tableMetadata.map((table) => (
                <li key={table.id} onClick={() => handleTableClick(table.id)} className={expandedTable === table.id ? 'active' : ''}>
                  {table.table_name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="main-content">
          <div className="table-metadata-container">
            {viewMode === 'metadata' && expandedTable && (
              <div className="metadata">
                <div className="metadata-header">
                  <h3>{tableMetadata.find(table => table.id === expandedTable)?.table_name}</h3>
                  <div className="table-description-container">
                    <label className="description-label">Table description:</label>
                    <textarea
                      className="table-description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      onBlur={(e) => handleSaveTableDescription(expandedTable, e.target.value)}
                    ></textarea>
                  </div>
                </div>
                {renderTableColumns()}
              </div>
            )}
            {viewMode === 'dataPreview' && (
              <div className="data-preview">
                {renderDataPreview()}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schema;
