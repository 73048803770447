import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'; // Correct imports
import axios from '../fetchWithCsrf';
import './SignUp.css';
import { useAuth } from '../AuthContext'; // Import useAuth hook
import API_BASE_URL from "../config";

const auth = getAuth();

const SignUp = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setAuth } = useAuth(); // Use the useAuth hook

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            // Create user with Firebase Auth
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const idToken = await user.getIdToken();

            // Store the ID token in React state using context
            setAuth(true, idToken);

            // Prepare the payload with name, email, and idToken
            const payload = {
                name: name,
                email: email,
                idToken: idToken
            };

            // Send the ID token to your backend for further processing
            const response = await axios.post(`${API_BASE_URL}/signup/`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                withCredentials: true,
            });

            if (response.status === 200) {
                navigate('/');
            } else {
                setError(response.data.error);
            }
        } catch (error) {
            console.error('Sign Up error:', error);
            setError('Failed to sign up. Please try again.');
        }
    };

    return (
        <div className="signup-container">
            <div className="logo">
                <img src="/icons/LogoNoBackground.png" alt="Spruce Logo" />
                <h1>Spruce</h1>
            </div>
            <form className="signup-form" onSubmit={handleSubmit}>
                <h2>Create your account</h2>
                {error && <div className="error-message">{error}</div>}
                <div className="input-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="email">Email address</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm your password"
                        required
                    />
                </div>
                <button type="submit" className="signup-button">Sign up</button>
                <div className="login-link">
                    Already have an account? <a href="/login">Log in</a>
                </div>
            </form>
        </div>
    );
};

export default SignUp;
