import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBrvb7PVpnY0jCxzEUp-zwo2UKp13h3WAs",
  authDomain: "robotic-land-439723-i8.firebaseapp.com",
  projectId: "robotic-land-439723-i8",
  appId: "1:383116158484:web:7dd4d8a711f08e013be901",
  measurementId: "G-WZ5X6VSJ86"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
export { auth };
