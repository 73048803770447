import React, { useState } from 'react';
import ReactModal from 'react-modal';
import DataSourceCard from '../components/DataSourceCard';
import axios from '../fetchWithCsrf';
import './DataSources.css';
import '../components/DataSourceCardModal.css';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from "../config";

ReactModal.setAppElement('#root');

const DataSourcesPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);
  const [credentials, setCredentials] = useState({
    customName: '',
    dataset_id: '',
    credentials_json: '',
    username: '',
    password: '',
    connectionString: '',
    account: '',
    warehouse: '',
    database: '',
    schema: '',
    role: ''
  });
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);

  const navigate = useNavigate();

  const availableSources = [
    { name: 'PostgreSQL', icon: '/icons/Postgresql-icon.png', buttonText: '+ Add', type: 'postgresql' },
    { name: 'MySQL', icon: '/icons/Mysql-icon.png', buttonText: '+ Add', type: 'mysql' },
    { name: 'Snowflake', icon: '/icons/Snowflake-icon.png', buttonText: '+ Add', type: 'snowflake' },
    { name: 'BigQuery', icon: '/icons/Bigquery-icon.png', buttonText: '+ Add', type: 'bigquery' },
    { name: 'CSV', icon: '/icons/Csv-icon.png', buttonText: '+ Add', type: 'csv' },
    { name: 'Microsoft SQL Server', icon: '/icons/Microsoftsql-icon.svg', buttonText: '+ Add', type: 'mssql' },
  ];

  const openModal = (source) => {
    setSelectedSource(source);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSource(null);
    setCredentials({
      customName: '',
      projectId: '',
      dataset_id: '',
      credentials_json: '',
      username: '',
      password: '',
      connectionString: '',
      account: '',
      warehouse: '',
      database: '',
      schema: '',
      role: ''
    });
    setMessage('');
    setIsSuccess(false);
    setIsLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleConnect = async () => {
    setIsLoading(true);
    try {
      const validationResponse = await axios.post(`${API_BASE_URL}/datasources/validate_connection/`, {
        ...credentials,
        data_source_type: selectedSource.type,
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        withCredentials: true,
      });

      if (validationResponse.status === 200) {
        const creationResponse = await axios.post(`${API_BASE_URL}/datasources/create_data_source/`, {
          ...credentials,
          data_source_type: selectedSource.type,
          customName: credentials.customName || `${selectedSource.name} Data Source`
        }, {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
        });

        if (creationResponse.status === 201) {
          setMessage('Connection successful. Redirecting...');
          setIsSuccess(true);
          setSuccessModalIsOpen(true);
        } else {
          setMessage(creationResponse.data.error || 'Connection unsuccessful. Please check your credentials and try again.');
        }
      } else {
        setMessage(validationResponse.data.error || 'Connection unsuccessful. Please check your credentials and try again.');
      }
    } catch (error) {
      setMessage('Connection unsuccessful. Please check your credentials and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderFormFields = () => {
    return (
      <>
        {/* Render Data Source Name only if it's not Snowflake */}
        {selectedSource?.type !== 'snowflake' && (
          <label className="form-label">
            Data Source Name:
            <input
              type="text"
              name="customName"
              value={credentials.customName}
              onChange={handleInputChange}
              placeholder="Enter a custom name"
              className="form-input"
            />
          </label>
        )}

        {selectedSource?.type === 'bigquery' && (
          <>
            <label className="form-label">
              Dataset ID:
              <input
                type="text"
                name="dataset_id"
                value={credentials.dataset_id}
                onChange={handleInputChange}
                className="form-input"
              />
            </label>
            <label className="form-label">
              Service Account Credentials:
              <textarea
                name="credentials_json"
                value={credentials.credentials_json}
                onChange={handleInputChange}
                placeholder="Paste your Service Account credentials here"
                className="form-textarea"
              />
            </label>
          </>
        )}

        {selectedSource?.type === 'snowflake' && (
          <div className="snowflake-fields">
            <div className="column">
              <label className="form-label">
                Data Source Name:
                <input
                  type="text"
                  name="customName"
                  value={credentials.customName}
                  onChange={handleInputChange}
                  placeholder="Enter a custom name"
                  className="form-input"
                />
              </label>
              <label className="form-label">
                Account:
                <input
                  type="text"
                  name="account"
                  value={credentials.account}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </label>
              <label className="form-label">
                Username:
                <input
                  type="text"
                  name="username"
                  value={credentials.username}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </label>
              <label className="form-label">
                Password:
                <input
                  type="password"
                  name="password"
                  value={credentials.password}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </label>
            </div>
            <div className="column">
              <label className="form-label">
                Database:
                <input
                  type="text"
                  name="database"
                  value={credentials.database}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </label>
              <label className="form-label">
                Schema:
                <input
                  type="text"
                  name="schema"
                  value={credentials.schema}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </label>
              <label className="form-label">
                Role:
                <input
                  type="text"
                  name="role"
                  value={credentials.role}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </label>
              <label className="form-label">
                Warehouse:
                <input
                  type="text"
                  name="warehouse"
                  value={credentials.warehouse}
                  onChange={handleInputChange}
                  className="form-input"
                />
              </label>
            </div>
          </div>
        )}

        {(selectedSource?.type === 'postgresql' || selectedSource?.type === 'mysql' || selectedSource?.type === 'mssql') && (
          <>
            <label className="form-label">
              Connection String:
              <input
                type="text"
                name="connectionString"
                value={credentials.connectionString}
                onChange={handleInputChange}
                className="form-input"
              />
            </label>
            <label className="form-label">
              Username:
              <input
                type="text"
                name="username"
                value={credentials.username}
                onChange={handleInputChange}
                className="form-input"
              />
            </label>
            <label className="form-label">
              Password:
              <input
                type="password"
                name="password"
                value={credentials.password}
                onChange={handleInputChange}
                className="form-input"
              />
            </label>
          </>
        )}

        {selectedSource?.type === 'csv' && (
          <label className="form-label">
            File:
            <input
              type="file"
              name="file"
              onChange={handleInputChange}
              className="form-input"
            />
          </label>
        )}
      </>
    );
  };


  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
    navigate('/my-data-sources');
  };

  return (
    <div className="data-sources-page">
      <div className="data-sources-back-button-container">
        <button onClick={() => navigate('/my-data-sources')} className="data-sources-back-button">
          <img src="/icons/arrow-back.svg" alt="Back" className="data-sources-back-arrow" /> Back
        </button>
      </div>
      <h2>Add Data Source</h2>
      <div className="data-source-cards">
        {availableSources.map((source, index) => (
          <DataSourceCard key={index} name={source.name} icon={source.icon} buttonText={source.buttonText} openModal={() => openModal(source)} />
        ))}
      </div>
      <ReactModal isOpen={modalIsOpen} onRequestClose={closeModal} className="data-source-modal" overlayClassName="data-source-modal-overlay">
        <h2>Connect to {selectedSource?.name}</h2>
        {isLoading ? (
          <div className="loading-screen">
            <img src="/icons/Logo-loading.gif" alt="Loading..." className="loading-gif" />
            <p>Connecting...</p>
          </div>
        ) : (
          <>
            {message && <p className={isSuccess ? 'success-message' : 'error-message'}>{message}</p>}
            <div className="modal-form-fields">
              {renderFormFields()}
            </div>
            <div className="modal-buttons">
              <button onClick={closeModal} className="cancel-button">Cancel</button>
              <button onClick={handleConnect} className="data-sources-connect-button">Connect</button>
            </div>
          </>
        )}
      </ReactModal>

      <ReactModal isOpen={successModalIsOpen} onRequestClose={closeSuccessModal} className="success-popup" overlayClassName="data-source-modal-overlay">
        <h2>Connection Successful</h2>
        <img src="/icons/success-icon.png" alt="Success" className="success-icon" />
        <button onClick={closeSuccessModal} className="continue-button">Continue</button>
      </ReactModal>
    </div>
  );
};

export default DataSourcesPage;
