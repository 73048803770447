import './ArchivedChats.css';
import React, { useEffect, useState } from 'react';
import axios from '../fetchWithCsrf';
import {useNavigate} from "react-router-dom"; // Ensure the correct import
import API_BASE_URL from "../config";

const ArchivedChats = () => {
    const [archivedChats, setArchivedChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [chatToDelete, setChatToDelete] = useState(null);
    const navigate = useNavigate(); // Add this line

    useEffect(() => {
        // Fetch the archived chats from the backend ${API_BASE_URL}
        axios.get(`${API_BASE_URL}/get-archived-chats/`, {
            withCredentials: true
        })
        .then((response) => {
            setArchivedChats(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error fetching archived chats:', error);
            setLoading(false);
        });
    }, []);

    const handleUnarchive = (chatId) => {
        axios.patch(`${API_BASE_URL}/unarchive-chat/${chatId}/`, {}, { withCredentials: true })
        .then((response) => {
            setArchivedChats(archivedChats.filter(chat => chat.id !== chatId));
        })
        .catch((error) => {
            console.error('Error unarchiving chat:', error);
        });
    };

    const handleDeleteChat = (chatId) => {
        // Set the chat to be deleted and show confirmation pop-up
        setChatToDelete(chatId);
        setShowDeletePopup(true);
    };

    const confirmDeleteChat = () => {
        if (chatToDelete) {
            // Perform the permanent delete using the chat ID stored in `chatToDelete`
            axios.delete(`${API_BASE_URL}/chats/${chatToDelete}/?permanent=true`, { withCredentials: true })
            .then((response) => {
                // Filter out the deleted chat from the archived chats list
                setArchivedChats(archivedChats.filter(chat => chat.id !== chatToDelete));
                setShowDeletePopup(false);
                setChatToDelete(null);
            })
            .catch((error) => {
                console.error('Error deleting chat:', error);
            });
        }
    };


    const cancelDelete = () => {
        setShowDeletePopup(false);
        setChatToDelete(null);
    };

    if (loading) {
        return <div>Loading archived chats...</div>;
    }

    return (
        <div className="archived-chats-page">
            <div className="data-sources-back-button-container">
                <button onClick={() => navigate('/settings')} className="data-sources-back-button">
                    <img src="/icons/arrow-back.svg" alt="Back" className="data-sources-back-arrow"/> Back
                </button>
            </div>
            <h1>Archived Chats</h1>
            <div className="archived-chats-list">
                {archivedChats.length > 0 ? (
                    archivedChats.map((chat) => (
                        <div key={chat.id} className="archived-chat-item">
                            <div className="chat-info">
                                <span className="archived-chat-title">{chat.title}</span>
                                <span
                                    className="chat-created">Created on: {new Date(chat.created_at).toLocaleDateString()}</span>
                            </div>
                            <div className="chat-actions">
                                <button className="unarchive-button" onClick={() => handleUnarchive(chat.id)}>
                                    Unarchive
                                </button>
                                <button className="archive-delete-button" onClick={() => handleDeleteChat(chat.id)}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No archived chats available.</p>
                )}
            </div>

            {/* Delete Confirmation Pop-up */}
            {showDeletePopup && (
                <div className="archive-delete-popup">
                    <div className="archive-popup-content">
                        <p>Are you sure you want to permanently delete this chat? This will also delete any elements you
                            have saved from this chat.</p>
                        <div className="archive-popup-actions">
                            <button onClick={cancelDelete} className="archive-cancel-delete-button">Cancel</button>
                            <button onClick={confirmDeleteChat} className="archive-confirm-delete-button">Yes, Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ArchivedChats;
