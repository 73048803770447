// EditDashboard.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Rnd } from 'react-rnd';
import axios from '../fetchWithCsrf';
import Visualization from '../components/Visualization';
import ReactModal from 'react-modal';
import './EditDashboard.css';
import { v4 as uuidv4 } from 'uuid';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PptxGenJS from 'pptxgenjs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import API_BASE_URL from "../config";




const EditDashboard = () => {
    const { id } = useParams();
    const [dashboard, setDashboard] = useState({ elements: [], name: 'New Dashboard', height: 1000 });
    const [availableElements, setAvailableElements] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [changesSaved, setChangesSaved] = useState(false);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [navigationTarget, setNavigationTarget] = useState(null);
    const [selectedElement, setSelectedElement] = useState(null);
    const [showAvailableElements, setShowAvailableElements] = useState(true);
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false);
    const shareDropdownRef = useRef(null);


    // New state for selected chart type
    const [selectedChartType, setSelectedChartType] = useState('');

    // Refresh scheduling states
    const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const [repeatOption, setRepeatOption] = useState('none');
    const [timeInput, setTimeInput] = useState('');
    const [timeError, setTimeError] = useState('');
    // Color picker dropdown states

    const [colorDropdownOpen, setColorDropdownOpen] = useState({
        barColor: false,
        borderColor: false,
        dotColor: false,
    });

    useEffect(() => {
        if (id) {
            axios.get(`${API_BASE_URL}/get-dashboard/${id}/`, {
                withCredentials: true,
            })
            .then(response => {
                const dashboardData = response.data;
                console.log("Fetched dashboard data:", dashboardData);

                // Map over elements to set selectedChartType
                dashboardData.elements = dashboardData.elements.map(element => ({
                    ...element,
                    selectedChartType: mapConfigToChartType(element.chart_config_override || element.chart_config),
                }));

                setDashboard({
                    ...dashboardData,
                    height: dashboardData.height || 1000
                });
            })
            .catch(error => console.error('Error fetching dashboard:', error));
        }

        axios.get(`${API_BASE_URL}/get-elements/`, {
            withCredentials: true,
        })
        .then(response => {
            console.log('Fetched available elements:', response.data);
            setAvailableElements(response.data);
        })
        .catch(error => console.error('Error fetching elements:', error));
    }, [id]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);

    // Close the dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);


    useEffect(() => {
        // Function to handle click outside dropdown
        const handleClickOutside = (event) => {
            if (shareDropdownRef.current && !shareDropdownRef.current.contains(event.target)) {
                setIsShareDropdownOpen(false);
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [shareDropdownRef]);


    const updateElementPositionAndSize = (elementId, left, top, width, height) => {
        console.log('Updating element position and size:', { elementId, left, top, width, height });
        setDashboard((prevDashboard) => {
            const updatedElements = prevDashboard.elements.map((element) => {
                if (element.id === elementId && element.type !== 'text') {
                    return {
                        ...element,
                        left,
                        top,
                        width,
                        height,
                        chart_config_override: element.chart_config_override || element.chart_config
                    };
                } else if (element.id === elementId && element.type === 'text') {
                    return {
                        ...element,
                        left,
                        top,
                        width,
                        height,
                    };
                }
                return element;
            });
            console.log('Updated elements:', updatedElements);
            return {
                ...prevDashboard,
                elements: updatedElements,
            };
        });
        setHasUnsavedChanges(true);
    };

    const handleAddElement = (element) => {
        console.log('Adding element:', element);
        setDashboard((prevDashboard) => ({
            ...prevDashboard,
            elements: [
                ...prevDashboard.elements,
                { ...element, left: 0, top: 0, width: 300, height: 200 },
            ],
        }));
        setHasUnsavedChanges(true);
    };

    const addTextBox = (textType = "New Text", fontSize) => {
        const newTextElement = {
            id: null,
            tempId: uuidv4(),
            type: 'text',
            content: textType,
            left: 0,
            top: 0,
            width: 300,
            height: 100,
            fontSize: fontSize,
            isBold: false,
            isItalic: false,
            hasBorder: true,
        };

        console.log('Adding new text element:', newTextElement);

        setDashboard(prevDashboard => ({
            ...prevDashboard,
            elements: [...prevDashboard.elements, newTextElement],
        }));
        setHasUnsavedChanges(true);
    };

    const handleSaveDashboard = () => {
        console.log('Saving dashboard:', dashboard);

        // Prepare elements data to send to backend
        const elementsToSave = dashboard.elements.map(e => ({
            id: e.id,
            tempId: e.tempId,
            type: e.type,
            content: e.content || null,
            chart_config_override: e.chart_config_override || null,
            sql_query: e.sql_query || null,
            data: e.data || null,
            left: e.left,
            top: e.top,
            width: e.width,
            height: e.height,
            fontSize: e.fontSize,
            isBold: e.isBold || false,
            isItalic: e.isItalic || false,
            hasBorder: e.hasBorder || false,
            legendDisplay: e.chart_config_override?.options?.plugins?.legend?.display || e.chart_config?.options?.plugins?.legend?.display || true,
            selectedChartType: e.selectedChartType, // Include selectedChartType
        }));

        axios.post(`${API_BASE_URL}/save-dashboard/`, {
            id: dashboard.id,
            name: dashboard.name,
            elements: elementsToSave,
            height: dashboard.height,
        }, {
            withCredentials: true,
        })
        .then(response => {
            console.log('Dashboard saved successfully:', response.data);

            // Update the dashboard state with the new element IDs
            setDashboard(prevDashboard => {
                const updatedElements = prevDashboard.elements.map(el => {
                    const updatedElement = response.data.elements.find(e => {
                        return (el.id && el.id === e.id) || (el.tempId && el.tempId === e.tempId);
                    });
                    if (updatedElement) {
                        const { tempId, ...rest } = el;
                        return {
                            ...rest,
                            id: updatedElement.id,
                            legendDisplay: updatedElement.legendDisplay,
                            selectedChartType: updatedElement.selectedChartType,
                        };
                    }
                    return el;
                });
                return {
                    ...prevDashboard,
                    id: response.data.id,
                    elements: updatedElements,
                };
            });

            setHasUnsavedChanges(false);
            setChangesSaved(true);
            setTimeout(() => setChangesSaved(false), 4000);
            if (navigationTarget) {
                navigate(navigationTarget);
            }
        })
        .catch(error => console.error('Error saving dashboard:', error));
    };

    const handleNavigationAway = (target) => {
        if (hasUnsavedChanges) {
            console.log('Navigation away with unsaved changes');
            setNavigationTarget(target);
            setIsModalOpen(true);
        } else {
            navigate(target);
        }
    };

    const handleExitWithoutSaving = () => {
        console.log('Exiting without saving');
        setIsModalOpen(false);
        setHasUnsavedChanges(false);
        if (navigationTarget) {
            navigate(navigationTarget);
        }
    };

    const mapConfigToChartType = (config) => {
        if (config?.data?.datasets) {
            const types = config.data.datasets.map(dataset => dataset.type || config.type);
            const hasBar = types.includes('bar');
            const hasLine = types.includes('line');

            if (hasBar && hasLine) return 'Bar Line Combo';
        }

        if (config?.type === 'line' && config?.isArea) return 'Area';
        if (config?.type === 'line') return 'Line';
        if (config?.type === 'bar' && config?.options?.scales?.x?.stacked) return 'Stacked Bar';
        if (config?.type === 'bar') return 'Bar';
        if (config?.type === 'pie') return 'Pie';

        return '';
    };

    const handleElementClick = (element) => {
        console.log('Element clicked:', element);

        const legendDisplay = element.chart_config_override?.options?.plugins?.legend?.display
            || element.chart_config?.options?.plugins?.legend?.display
            || element.legend_display;
        console.log('Legend display state:', legendDisplay);

        setSelectedElement({
            ...element,
            legend_display: legendDisplay !== undefined ? legendDisplay : true,
        });

        setSidebarOpen(true);
        setShowAvailableElements(false);

        // Set selectedChartType based on element or config
        const chartType = element.selectedChartType || mapConfigToChartType(element.chart_config_override || element.chart_config);
        setSelectedChartType(chartType);
    };

    const toggleColorDropdown = (type) => {
        setColorDropdownOpen((prev) => ({
            ...prev,
            [type]: !prev[type],
        }));
    };

    const updateSegmentColor = (datasetIndex, colorIndex, newColor, colorType) => {
        if (selectedElement) {
            setDashboard((prevDashboard) => {
                const updatedElements = prevDashboard.elements.map((element) => {
                    if (element.id === selectedElement.id) {
                        const dataset = element.chart_config_override?.data?.datasets[datasetIndex] || element.chart_config.data.datasets[datasetIndex];

                        const updatedColor = Array.isArray(dataset[colorType])
                            ? dataset[colorType].map((color, index) => index === colorIndex ? newColor : color)
                            : newColor;

                        const updatedDataset = {
                            ...dataset,
                            [colorType]: updatedColor,
                        };

                        const updatedDatasets = element.chart_config_override?.data?.datasets
                            ? element.chart_config_override.data.datasets.map((ds, idx) => idx === datasetIndex ? updatedDataset : ds)
                            : element.chart_config.data.datasets.map((ds, idx) => idx === datasetIndex ? updatedDataset : ds);

                        const updatedElement = {
                            ...element,
                            chart_config_override: {
                                ...element.chart_config_override || element.chart_config,
                                data: {
                                    ...element.chart_config_override?.data || element.chart_config.data,
                                    datasets: updatedDatasets,
                                },
                            },
                        };
                        return updatedElement;
                    }
                    return element;
                });
                return {
                    ...prevDashboard,
                    elements: updatedElements,
                };
            });
            setHasUnsavedChanges(true);
        }
    };

    const renderSegmentColorPickers = () => {
        if (!selectedElement || !selectedElement.chart_config?.data || !selectedElement.chart_config.data.datasets) {
            return null;
        }

        return selectedElement.chart_config.data.datasets.map((dataset, datasetIndex) => {
            const chartType = selectedElement.selectedChartType || mapConfigToChartType(selectedElement.chart_config_override || selectedElement.chart_config);

            switch (chartType) {
                case 'Bar':
                case 'Stacked Bar':
                    return (
                        <div key={datasetIndex}>
                            <h3>Chart Colors:</h3>

                            {/* General Bar Color */}
                            <label className="color-input-container">
                                Bar Color:
                                <input
                                    type="color"
                                    value={Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor[0] : dataset.backgroundColor || ''}
                                    onChange={(e) => updateSegmentColor(datasetIndex, 0, e.target.value, 'backgroundColor')}
                                    className="color-input"
                                />
                                <button onClick={() => toggleColorDropdown('barColor')} className="color-dropdown-button">
                                    <span className="custom-arrow"></span>
                                </button>
                            </label>

                            {/* Specific Bar Colors */}
                            {colorDropdownOpen.barColor && Array.isArray(dataset.backgroundColor) && (
                                <div className="color-picker-dropdown" style={{ zIndex: 10, position: 'absolute' }}>
                                    {dataset.backgroundColor.map((color, colorIndex) => (
                                        <div key={colorIndex}>
                                            <label>
                                                Bar {colorIndex + 1} Color:
                                                <input
                                                    type="color"
                                                    value={color || ''}
                                                    onChange={(e) => updateSegmentColor(datasetIndex, colorIndex, e.target.value, 'backgroundColor')}
                                                    className="color-input"
                                                />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Bar Border Color */}
                            <label className="color-input-container">
                                Border Color:
                                <input
                                    type="color"
                                    value={Array.isArray(dataset.borderColor) ? dataset.borderColor[0] : dataset.borderColor || '#000000'}
                                    onChange={(e) => updateSegmentColor(datasetIndex, 0, e.target.value, 'borderColor')}
                                    className="color-input"
                                />
                                <button onClick={() => toggleColorDropdown('borderColor')} className="color-dropdown-button">
                                    <span className="custom-arrow"></span>
                                </button>
                            </label>

                            {/* Specific Border Colors */}
                            {colorDropdownOpen.borderColor && Array.isArray(dataset.borderColor) && (
                                <div className="color-picker-dropdown" style={{ zIndex: 10, position: 'absolute' }}>
                                    {dataset.borderColor.map((color, colorIndex) => (
                                        <div key={colorIndex}>
                                            <label>
                                                Bar {colorIndex + 1} Border Color:
                                                <input
                                                    type="color"
                                                    value={color || '#000000'}
                                                    onChange={(e) => updateSegmentColor(datasetIndex, colorIndex, e.target.value, 'borderColor')}
                                                    className="color-input"
                                                />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );

                default:
                    return null;
            }
        });
    };

    const handleChartTypeChange = async (newType) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/modify-chart/`, {
                chartType: newType,
                sqlQuery: selectedElement.sql_query,
                queryResults: selectedElement.data,
                chartConfig: selectedElement.chart_config_override || selectedElement.chart_config,
            }, {
                withCredentials: true,
            });

            const newConfig = response.data;

            const updatedElement = {
                ...selectedElement,
                chart_config_override: newConfig,
                selectedChartType: newType, // Update selectedChartType
            };

            setDashboard((prevDashboard) => {
                const updatedElements = prevDashboard.elements.map((element) =>
                    element.id === selectedElement.id ? updatedElement : element
                );
                return {
                    ...prevDashboard,
                    elements: updatedElements,
                };
            });

            setSelectedElement(updatedElement);
            setSelectedChartType(newType); // Update selectedChartType state
            setHasUnsavedChanges(true);
        } catch (error) {
            console.error('Error generating new chart config:', error);
        }
    };


    const renderDynamicControls = () => {
        if (!selectedElement) {
            return null;
        }

        if (selectedElement.type === 'text') {
            // Render text element controls
            return (
                <div className="edit-text-options">
                    <h3>Edit Text</h3>
                    <label>
                        <input
                            type="checkbox"
                            checked={selectedElement?.isBold || false}
                            onChange={() => handleTextStyleChange(selectedElement.id, 'isBold', !selectedElement.isBold)}
                        />
                        Bold
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={selectedElement?.isItalic || false}
                            onChange={() => handleTextStyleChange(selectedElement.id, 'isItalic', !selectedElement.isItalic)}
                        />
                        Italics
                    </label>
                    <label>
                        Font Size:
                        <input
                            type="number"
                            value={selectedElement.fontSize}
                            onChange={(e) => handleTextStyleChange(selectedElement.id, 'fontSize', parseInt(e.target.value))}
                        />
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={selectedElement?.hasBorder || false}
                            onChange={() => handleTextStyleChange(selectedElement.id, 'hasBorder', !selectedElement.hasBorder)}
                        />
                        Show Border
                    </label>
                    <button onClick={handleDeleteElement} className="delete-element-button">Delete Element</button>
                </div>
            );
        }

        const chartConfigOverride = selectedElement.chart_config_override || selectedElement.chart_config;

        // Calculate the appropriate chart types based on the data
        const variableCount = chartConfigOverride.data?.labels?.length || 0;
        const datasetCount = chartConfigOverride.data?.datasets?.length || 0;

        // Determine applicable chart types based on the data structure
        let availableChartTypes = [];

        if (variableCount > 1 && datasetCount === 1) {
            availableChartTypes.push('Pie');
        }

        if (variableCount > 1) {
            availableChartTypes.push('Bar', 'Line', 'Area');
        }

        if (datasetCount > 1) {
            availableChartTypes.push('Stacked Bar', 'Bar Line Combo');
        }

        return (
            <>
                <label>
                    Chart Type:
                    <select
                        value={selectedChartType}
                        onChange={(e) => handleChartTypeChange(e.target.value)}
                    >
                        {availableChartTypes.map((chartType) => (
                            <option key={chartType} value={chartType}>
                                {chartType}
                            </option>
                        ))}
                    </select>
                </label>
                <label>
                    Chart Title:
                    <input
                        type="text"
                        value={chartConfigOverride.options?.plugins?.title?.text || ''}
                        onChange={(e) => handleEditChange('title', e.target.value)}
                    />
                </label>
                <label>
                    X-Axis Label:
                    <input
                        type="text"
                        value={chartConfigOverride.options?.scales?.x?.title?.text || ''}
                        onChange={(e) => handleEditChange('xAxisLabel', e.target.value)}
                    />
                </label>
                <label>
                    Y-Axis Label:
                    <input
                        type="text"
                        value={chartConfigOverride.options?.scales?.y?.title?.text || ''}
                        onChange={(e) => handleEditChange('yAxisLabel', e.target.value)}
                    />
                </label>
                <label>
                    Legend:
                    <select
                        value={chartConfigOverride?.options?.plugins?.legend?.display ? 'yes' : 'no'}
                        onChange={(e) => handleEditChange('legend', e.target.value === 'yes')}
                    >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </label>
                <label>
                    Element Border:
                    <select
                        value={selectedElement.hasBorder ? 'yes' : 'no'}
                        onChange={(e) => handleEditChange('hasBorder', e.target.value === 'yes')}
                    >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </label>
                <label>
                    Data Labels:
                    <select
                        value={selectedElement.chart_config_override?.showDataLabels ? 'yes' : 'no'}
                        onChange={(e) => handleEditChange('dataLabels', e.target.value === 'yes')}
                    >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </label>

                {selectedElement.chart_config_override?.showDataLabels && (
                    <label>
                        Data Label Offset:
                        <input
                            type="number"
                            value={selectedElement.chart_config_override?.dataLabelOffset || 0}
                            onChange={(e) => handleEditChange('dataLabelOffset', Number(e.target.value))}
                        />
                    </label>
                )}

                {renderSegmentColorPickers()}
                <button onClick={handleDeleteElement} className="delete-element-button">Delete Element</button>
            </>
        );
    };

    const handleEditChange = (field, value) => {
        if (selectedElement) {
            const newChartConfigOverride = {
                ...selectedElement.chart_config_override || {},
                type: selectedElement.chart_config_override?.type || selectedElement.chart_config.type,
                // Add showDataLabels and dataLabelOffset at the top level
                showDataLabels: field === 'dataLabels' ? value : (selectedElement.chart_config_override?.showDataLabels || false),
                dataLabelOffset: field === 'dataLabelOffset' ? value : (selectedElement.chart_config_override?.dataLabelOffset || 0),
                options: {
                    ...selectedElement.chart_config_override?.options || {},
                    scales: {
                        ...selectedElement.chart_config_override?.options?.scales || {},
                        x: {
                            ...selectedElement.chart_config_override?.options?.scales?.x || {},
                            title: {
                                text:
                                    field === 'xAxisLabel'
                                        ? value
                                        : selectedElement.chart_config_override?.options?.scales?.x?.title?.text || '',
                            },
                        },
                        y: {
                            ...selectedElement.chart_config_override?.options?.scales?.y || {},
                            title: {
                                text:
                                    field === 'yAxisLabel'
                                        ? value
                                        : selectedElement.chart_config_override?.options?.scales?.y?.title?.text || '',
                            },
                        },
                    },
                    plugins: {
                        ...selectedElement.chart_config_override?.options?.plugins || {},
                        title: {
                            text:
                                field === 'title'
                                    ? value
                                    : selectedElement.chart_config_override?.options?.plugins?.title?.text || '',
                        },
                        legend: {
                            display:
                                field === 'legend'
                                    ? value
                                    : selectedElement.chart_config_override?.options?.plugins?.legend?.display || true,
                        },
                        // Remove datalabels from here since we're moving it to the top level
                    },
                },
            };

            const updatedElement = {
                ...selectedElement,
                chart_config_override: newChartConfigOverride,
                ...(field === 'hasBorder' ? { hasBorder: value } : {}),
            };

            setDashboard((prevDashboard) => {
                const updatedElements = prevDashboard.elements.map((element) =>
                    element.id === selectedElement.id ? updatedElement : element
                );
                return {
                    ...prevDashboard,
                    elements: updatedElements,
                };
            });

            setSelectedElement(updatedElement);
            setHasUnsavedChanges(true);
        }
    };



    // Add 'async' before the function definition
    const handleDeleteElement = async () => {
        console.log('Deleting element:', selectedElement);

        // Check if the element is unsaved (has no ID but has a tempId)
        if (!selectedElement.id && selectedElement.tempId) {
            console.log('Unsaved element (text), removing it from the frontend only.');

            // Remove the unsaved element from the frontend state (no API call)
            setDashboard((prevDashboard) => ({
                ...prevDashboard,
                elements: prevDashboard.elements.filter(el => el.tempId !== selectedElement.tempId),
            }));

            setSelectedElement(null);
            setHasUnsavedChanges(true);
            return;  // No need to proceed further for unsaved elements
        }

        try {
            // For saved elements, send a DELETE request to the backend
            const response = await axios.delete(`${API_BASE_URL}/delete-dashboard-element/${dashboard.id}/${selectedElement.id}/`, {
                withCredentials: true,
            });

            // If the backend successfully deletes the DashboardElement
            if (response.status === 204) {
                console.log('Element unlinked from dashboard successfully:', response.data);

                // Remove from frontend state
                setDashboard((prevDashboard) => ({
                    ...prevDashboard,
                    elements: prevDashboard.elements.filter(el => el.id !== selectedElement.id),
                }));
                setSelectedElement(null);
                setHasUnsavedChanges(true);

            } else {
                // Handle any unexpected responses here
                console.error('Unexpected response:', response);
            }
        } catch (error) {
            if (error.response && error.response.status === 404 && error.response.data.error === 'Not linked to dashboard') {
                console.log('Element not linked to the dashboard, handling frontend-only deletion.');

                // Remove the element from the frontend (canvas-only deletion)
                setDashboard((prevDashboard) => ({
                    ...prevDashboard,
                    elements: prevDashboard.elements.filter(el => el.id !== selectedElement.id),
                }));
                setSelectedElement(null);
                setHasUnsavedChanges(true);
            } else {
                console.error('Error deleting element:', error);
            }
        }
    };




    const handleTextStyleChange = (elementId, styleProperty, value) => {
        setDashboard(prevDashboard => ({
            ...prevDashboard,
            elements: prevDashboard.elements.map(element =>
                element.id === elementId ? { ...element, [styleProperty]: value } : element
            ),
        }));

        if (selectedElement && selectedElement.id === elementId) {
            setSelectedElement(prevSelectedElement => ({
                ...prevSelectedElement,
                [styleProperty]: value
            }));
        }

        setHasUnsavedChanges(true);
    };

    const handleTextEdit = (e, elementId) => {
        const updatedText = e.target.value;
        console.log('Updating text content for element ID:', elementId, 'with new content:', updatedText);
        setDashboard(prevDashboard => ({
            ...prevDashboard,
            elements: prevDashboard.elements.map(element =>
                element.id === elementId ? { ...element, content: updatedText } : element
            ),
        }));
        setHasUnsavedChanges(true);
    };

    const renderTextElement = (element) => {
        return (
            <Rnd
                key={element.id}
                size={{ width: element.width, height: element.height }}
                position={{ x: element.left, y: element.top }}
                onDragStop={(e, d) =>
                    updateElementPositionAndSize(element.id, d.x, d.y, element.width, element.height)
                }
                onResizeStop={(e, direction, ref, delta, position) => {
                    updateElementPositionAndSize(
                        element.id,
                        position.x,
                        position.y,
                        parseInt(ref.style.width),
                        parseInt(ref.style.height)
                    );
                }}
                bounds="parent"
                disableDragging={isEditing}
                style={{
                    border: element.hasBorder ? '1px solid #ccc' : 'none',
                    padding: element.hasBorder ? '10px' : '0px',
                    backgroundColor: 'transparent',
                    borderRadius: '5px',
                }}
            >
                <textarea
                    value={element.content}
                    onFocus={(e) => {
                        e.stopPropagation();
                        handleElementClick(element);
                        setIsEditing(true);
                    }}
                    onBlur={() => {
                        setIsEditing(false);
                    }}
                    onChange={(e) => handleTextEdit(e, element.id)}
                    style={{
                        fontSize: `${element.fontSize}px`,
                        fontWeight: element.isBold ? 'bold' : 'normal',
                        fontStyle: element.isItalic ? 'italic' : 'normal',
                        cursor: 'text',
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        outline: 'none',
                        resize: 'none',
                        fontFamily: 'Poppins, sans-serif',
                        backgroundColor: 'transparent', // Set background to transparent
                    }}
                />
            </Rnd>
        );
    };

    const generateChartCommentary = async (element) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/generate-commentary_edit/`, {
                sql_query: element.sql_query,
                query_results: element.data,
                conversation_context: [],
            }, {
                withCredentials: true,
            });

            const generatedCommentary = response.data.commentary;

            const tempId = uuidv4();

            const newCommentaryElement = {
                id: null,
                tempId: tempId,
                type: 'text',
                content: generatedCommentary,
                fontSize: 16,
                isBold: false,
                isItalic: false,
                hasBorder: false,
                width: element.width,
                height: 100,
                left: element.left,
                top: element.top + element.height + 10,
            };

            console.log('Adding new commentary element:', newCommentaryElement);

            setDashboard(prevDashboard => ({
                ...prevDashboard,
                elements: [
                    ...prevDashboard.elements,
                    newCommentaryElement
                ]
            }));

            setHasUnsavedChanges(true);
        } catch (error) {
            console.error("Error generating chart commentary:", error);
        }
    };

    const handleRefreshNow = () => {
        axios.post(`${API_BASE_URL}/refresh-dashboard-now/${id}/`, null, {withCredentials: true})
            .then(response => {
                console.log('Dashboard refreshed:', response.data);
            })
            .catch(error => console.error('Error refreshing dashboard:', error));
    };

    const handleScheduleRefresh = () => {
        if (!validateTimeInput(timeInput)) {
            setTimeError('Please enter a valid time in HH:MM AM/PM format.');
            return;
        }

        setTimeError('');

        const scheduleData = {
            selectedDays,
            timeInput,
            repeatOption,
        };

        axios.post(`${API_BASE_URL}/set-dashboard-refresh-schedule/${id}/`, {
            scheduleData
        }, {
            withCredentials: true
        })
        .then(response => {
            console.log('Schedule set successfully');
            setScheduleModalOpen(false);
        })
        .catch(error => {
            console.error('Error setting schedule:', error);
        });
    };

    const validateTimeInput = (time) => {
        const timeFormat = /^([1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/i;
        return timeFormat.test(time);
    };

    const handleDaySelection = (day) => {
        setSelectedDays(prevDays =>
            prevDays.includes(day)
                ? prevDays.filter(d => d !== day)
                : [...prevDays, day]
        );
    };

    const filteredElements = availableElements.filter((element) =>
        element.name && element.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleIncreaseHeight = () => {
        console.log('Dashboard state before increasing:', dashboard);
        if (typeof dashboard.height !== 'number' || isNaN(dashboard.height)) {
            console.error('Invalid height value:', dashboard.height);
            return;
        }

        setDashboard((prevDashboard) => {
            const newHeight = prevDashboard.height + 500;
            console.log('New height after increasing:', newHeight);
            return {
                ...prevDashboard,
                height: newHeight,
            };
        });
        setHasUnsavedChanges(true);
    };

    const handleDecreaseHeight = () => {
        console.log('Dashboard state before decreasing:', dashboard);
        if (typeof dashboard.height !== 'number' || isNaN(dashboard.height)) {
            console.error('Invalid height value:', dashboard.height);
            return;
        }

        const bottomLimit = dashboard.height - 500;
        const canDecrease = !dashboard.elements.some(element => element.top + element.height > bottomLimit);

        if (canDecrease) {
            console.log('New height after decreasing:', bottomLimit);
            setDashboard((prevDashboard) => ({
                ...prevDashboard,
                height: bottomLimit,
            }));
            setHasUnsavedChanges(true);
        } else {
            console.log('Cannot decrease height - elements present in the area to be removed');
        }
    };

    const handleExportToPDF = () => {
      console.log('Starting PDF export...');

      const chartElements = dashboard.elements.filter(element => element.type !== 'text');

      if (!chartElements || chartElements.length === 0) {
        alert('No chart elements available to export.');
        return;
      }

      const pdf = new jsPDF('p', 'mm', 'a4');
      console.log('Initialized jsPDF.');

      let currentY = 10; // Starting Y position in mm
      const pageHeight = pdf.internal.pageSize.getHeight();
      const pageWidth = pdf.internal.pageSize.getWidth();
      const margin = 10; // Margin in mm

      const processNextChart = (index) => {
        if (index >= chartElements.length) {
          // All charts processed, save the PDF
          pdf.save(`${dashboard.name}.pdf`);
          console.log(`PDF saved as: ${dashboard.name}.pdf`);
          return;
        }

        const element = chartElements[index];
        const elementRef = document.getElementById(`chart-element-${element.id}`);

        if (!elementRef) {
          console.warn(`Element with id chart-element-${element.id} not found.`);
          processNextChart(index + 1);
          return;
        }

        html2canvas(elementRef, { scale: 2 })
          .then((canvas) => {
            console.log(`Canvas generated for element ${element.id}:`, canvas);

            const imgData = canvas.toDataURL('image/png');
            console.log('Image data generated.');

            const imgWidth = pageWidth - margin * 2;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            if (currentY + imgHeight > pageHeight - margin) {
              // Add a new page if the image doesn't fit
              pdf.addPage();
              currentY = margin;
            }

            pdf.addImage(imgData, 'PNG', margin, currentY, imgWidth, imgHeight);
            console.log(`Image added to PDF at position Y: ${currentY}`);

            currentY += imgHeight + margin; // Update Y position for next image

            processNextChart(index + 1); // Process next chart
          })
          .catch((error) => {
            console.error(`Error generating canvas for element ${element.id}:`, error);
            processNextChart(index + 1); // Skip to next chart on error
          });
      };

      processNextChart(0); // Start processing charts
    };

    const handleExportToPPTX = () => {
      console.log('Starting PPTX export...');

      const chartElements = dashboard.elements.filter(element => element.type !== 'text');

      if (!chartElements || chartElements.length === 0) {
        alert('No chart elements available to export.');
        return;
      }

      const pptx = new PptxGenJS();
      console.log('Initialized PptxGenJS.');

      const processNextChart = (index) => {
        if (index >= chartElements.length) {
          // All charts processed, save the PPTX
          pptx.writeFile({ fileName: `${dashboard.name}.pptx` }).then(() => {
            console.log(`PPTX saved as: ${dashboard.name}.pptx`);
          });
          return;
        }

        const element = chartElements[index];
        const elementRef = document.getElementById(`chart-element-${element.id}`);

        if (!elementRef) {
          console.warn(`Element with id chart-element-${element.id} not found.`);
          processNextChart(index + 1);
          return;
        }

        html2canvas(elementRef, { scale: 3 }) // Increase scale for better resolution
          .then((canvas) => {
            console.log(`Canvas generated for element ${element.id}:`, canvas);

            const imgData = canvas.toDataURL('image/png');
            console.log('Image data generated.');

            const slide = pptx.addSlide();
            console.log('Added new slide to presentation.');

            const slideWidth = pptx.width;
            const slideHeight = pptx.height;

            // Directly fit the image to slide dimensions
            const imgWidth = slideWidth;
            const imgHeight = (canvas.height * slideWidth) / canvas.width;

            // If image height exceeds the slide height, adjust accordingly
            const finalWidth = imgHeight > slideHeight ? (canvas.width * slideHeight) / canvas.height : imgWidth;
            const finalHeight = imgHeight > slideHeight ? slideHeight : imgHeight;

            // Center the image on the slide
            const xPos = (slideWidth - finalWidth) / 2;
            const yPos = (slideHeight - finalHeight) / 2;

            slide.addImage({
              data: imgData,
              x: xPos,
              y: yPos,
              w: finalWidth,
              h: finalHeight,
            });
            console.log(`Image added to PPTX slide for element ${element.id}.`);

            processNextChart(index + 1); // Process next chart
          })
          .catch((error) => {
            console.error(`Error generating canvas for element ${element.id}:`, error);
            processNextChart(index + 1); // Skip to next chart on error
          });
      };

      processNextChart(0); // Start processing charts
    };






    const handleExportDataToCSV = () => {
      const elements = dashboard.elements;

      if (!elements || elements.length === 0) {
        alert('No elements available to export.');
        return;
      }

      const zip = new JSZip();

      elements.forEach((element, idx) => {
        if (element.type === 'text') {
          // Skip text elements
          return;
        }

        // Use the data from the element
        let data = element.data;

        // If data is not directly available, try to use the chart's data
        if (!data || data.length === 0) {
          data = element.chart_config_override?.data || element.chart_config?.data;
        }

        if (!data || data.length === 0) {
          console.warn(`No data available to export for element ${element.name || idx + 1}.`);
          return;
        }

        let csvRows = [];

        // Check if data is an array of objects (SQL query results)
        if (Array.isArray(data)) {
          // Handle data from SQL query results
          const dataArray = data.flat();

          if (dataArray.length === 0) {
            console.warn(`No data available to export for element ${element.name || idx + 1}.`);
            return;
          }

          if (typeof dataArray[0] !== 'object' || dataArray[0] === null) {
            console.warn(`Data format is not supported for element ${element.name || idx + 1}.`);
            return;
          }

          // Extract headers
          const headers = Object.keys(dataArray[0]);
          csvRows.push(headers.join(','));

          // Loop through the data and add each row
          dataArray.forEach((row) => {
            const values = headers.map((header) => `"${row[header] || ''}"`);
            csvRows.push(values.join(','));
          });
        } else if (data.labels && data.datasets) {
          // Handle chart data
          const headers = ['Label', ...data.datasets.map((dataset) => dataset.label)];
          csvRows.push(headers.join(','));

          // Get the number of data points
          const numRows = data.labels.length;
          for (let i = 0; i < numRows; i++) {
            const row = [data.labels[i]];
            for (const dataset of data.datasets) {
              row.push(dataset.data[i]);
            }
            csvRows.push(row.join(','));
          }
        } else {
          console.warn(`Data format is not supported for element ${element.name || idx + 1}.`);
          return;
        }

        const csvContent = csvRows.join('\n');

        // Add the CSV content to the ZIP file
        const fileName = `${element.name || 'element_' + (idx + 1)}.csv`;
        zip.file(fileName, csvContent);
      });

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${dashboard.name || 'dashboard_data_export'}.zip`);
      });
    };



    const handleExportDataToExcel = () => {
      const elements = dashboard.elements;

      if (!elements || elements.length === 0) {
        alert('No elements available to export.');
        return;
      }

      const workbook = XLSX.utils.book_new();

      elements.forEach((element, idx) => {
        if (element.type === 'text') {
          // Skip text elements
          return;
        }

        // Use the data from the element
        let data = element.data;

        // If data is not directly available, try to use the chart's data
        if (!data || data.length === 0) {
          data = element.chart_config_override?.data || element.chart_config?.data;
        }

        if (!data || data.length === 0) {
          console.warn(`No data available to export for element ${element.name || idx + 1}.`);
          return;
        }

        let worksheetData = [];

        // Check if data is an array of objects (SQL query results)
        if (Array.isArray(data)) {
          // Handle data from SQL query results
          const dataArray = data.flat();

          if (dataArray.length === 0) {
            console.warn(`No data available to export for element ${element.name || idx + 1}.`);
            return;
          }

          if (typeof dataArray[0] !== 'object' || dataArray[0] === null) {
            console.warn(`Data format is not supported for element ${element.name || idx + 1}.`);
            return;
          }

          // Extract headers
          const headers = Object.keys(dataArray[0]);
          worksheetData.push(headers);

          // Loop through the data and add each row
          dataArray.forEach((row) => {
            const values = headers.map((header) => row[header] || '');
            worksheetData.push(values);
          });
        } else if (data.labels && data.datasets) {
          // Handle chart data
          const headers = ['Label', ...data.datasets.map((dataset) => dataset.label)];
          worksheetData.push(headers);

          // Get the number of data points
          const numRows = data.labels.length;
          for (let i = 0; i < numRows; i++) {
            const row = [data.labels[i]];
            for (const dataset of data.datasets) {
              row.push(dataset.data[i]);
            }
            worksheetData.push(row);
          }
        } else {
          console.warn(`Data format is not supported for element ${element.name || idx + 1}.`);
          return;
        }

        // Create worksheet and append to workbook
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const sheetName = element.name ? element.name.substring(0, 31) : `Element${idx + 1}`; // Sheet names can't exceed 31 chars
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      });

      // Check if workbook has any sheets
      if (workbook.SheetNames.length === 0) {
        alert('No data available to export.');
        return;
      }

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, `${dashboard.name || 'dashboard_data_export'}.xlsx`);
    };



    const handleShareViaEmail = () => {
      // You can customize the email subject and body here
      const subject = encodeURIComponent(`Sharing Dashboard: ${dashboard.name}`);
      const body = encodeURIComponent(`Hi,\n\nI wanted to share this dashboard with you: ${dashboard.name}.\n\nLink: [Insert link to dashboard or export]\n\nBest regards,\n[Your Name]`);

      // Open the user's default email client with the subject and body pre-filled
      window.location.href = `mailto:?subject=${subject}&body=${body}`;
    };


    return (
        <DndProvider backend={HTML5Backend}>
            <div className="edit-dashboard">
                <div className="edit-dashboard-header">
                    <div className="back-and-name">
                        <button onClick={() => handleNavigationAway('/dashboards')} className="edit-dashboard-back-button">
                            <img src="/icons/arrow-back.svg" alt="Back" className="edit-dashboard-back-arrow" /> Back
                        </button>
                        <div className="dashboard-name-and-status">
                            <h1 className="dashboard-name">{dashboard.name}</h1>
                            {hasUnsavedChanges && (
                                <span className="unsaved-changes-indicator-dashboard">Unsaved changes</span>
                            )}
                            {!hasUnsavedChanges && changesSaved && (
                                <span className="saved-changes-indicator-dashboard">Changes Saved</span>
                            )}
                        </div>
                    </div>
                    <div className="header-buttons">
                        <button
                            onClick={() => {
                                setSidebarOpen(true);
                                setShowAvailableElements(true);
                                setSelectedElement(null);
                            }}
                            className="add-element-button"
                        >
                            Add Element
                        </button>
                        <button onClick={handleSaveDashboard} className="save-dashboard-button">Save Dashboard</button>

                        {/* Dropdown Refresh Button */}
                        <div className="refresh-dropdown-container" ref={dropdownRef}>
                            <button
                                className="refresh-dropdown-button"
                                onClick={() => setDropdownOpen(!isDropdownOpen)}
                            >
                                Refresh
                            </button>
                            {isDropdownOpen && (
                                <ul className="refresh-dropdown-menu">
                                    <li onClick={handleRefreshNow}>Refresh now</li>
                                    <li onClick={() => setScheduleModalOpen(true)}>Schedule refresh</li>
                                </ul>
                            )}
                        </div>
                        {/* Dropdown Share Button */}
                        <div className="share-dropdown-container" ref={shareDropdownRef}>
                            <button
                                className="share-dropdown-button"
                                onClick={() => setIsShareDropdownOpen(!isShareDropdownOpen)}
                            >
                                Share/Export
                            </button>
                            {isShareDropdownOpen && (
                                <ul className="share-dropdown-menu">
                                    <li onClick={handleShareViaEmail}>Share via Email</li>
                                    <li onClick={handleExportToPDF}>Export to PDF</li>
                                    <li onClick={handleExportToPPTX}>Export to PowerPoint</li>
                                    <li onClick={handleExportDataToExcel}>Export Data to Excel</li>
                                    <li onClick={handleExportDataToCSV}>Export Data to CSV</li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>

                <div
                    id="dashboard-container"
                    className="edit-dashboard-container"
                    key={dashboard.height}
                    style={{
                        height: `${dashboard.height}px`,
                        overflowY: 'scroll',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        transition: 'height 0.3s ease',
                        position: 'relative'
                    }}
                >
                    {dashboard.elements.length === 0 ? (
                        <div className="empty-message">Add an element...</div>
                    ) : (
                        dashboard.elements.map((element) => {
                            return element.type === 'text' ? (
                                renderTextElement(element)
                            ) : (
                                <Rnd
                                    key={`${element.id}-${JSON.stringify(element.chart_config_override || element.chart_config)}`}
                                    id={`chart-element-${element.id}`}
                                    size={{ width: element.width, height: element.height }}
                                    position={{ x: element.left, y: element.top }}
                                    onDragStop={(e, d) => updateElementPositionAndSize(element.id, d.x, d.y, element.width, element.height)}
                                    onResizeStop={(e, direction, ref, delta, position) => {
                                        updateElementPositionAndSize(element.id, position.x, position.y, parseInt(ref.style.width), parseInt(ref.style.height));
                                    }}
                                    bounds="parent"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleElementClick(element);
                                    }}
                                    style={{
                                        border: element.hasBorder ? '1px solid #ccc' : 'none',
                                        borderRadius: '5px',
                                        padding: element.hasBorder ? '10px' : '0px',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <Visualization
                                        config={element.chart_config_override || element.chart_config}
                                        requestedChartType={element.selectedChartType || mapConfigToChartType(element.chart_config_override || element.chart_config)}
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                </Rnd>
                            );
                        })
                    )}

                    {/* Height Adjustment Controls */}
                    <div style={{
                        textAlign: 'center',
                        color: 'lightgrey',
                        marginTop: '10px',
                        cursor: 'pointer',
                        position: 'absolute',
                        bottom: '10px',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}>
                        <span onClick={handleDecreaseHeight} style={{ marginRight: '10px' }}>-</span>
                        <span>Height</span>
                        <span onClick={handleIncreaseHeight} style={{ marginLeft: '10px' }}>+</span>
                    </div>
                </div>

                {isSidebarOpen && (
                    <div className="available-elements-sidebar" style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                        <div className="sidebar-header">
                            <img
                                src="/icons/Minimize-icon.png"
                                alt="Close Sidebar"
                                className="toggle-sidebar-icon"
                                onClick={() => setSidebarOpen(false)}
                            />
                            {showAvailableElements ? (
                                <>
                                    <h2>Add Text</h2>
                                    <div className="add-text-options">
                                        <button onClick={() => addTextBox('Heading 1', 24)} className="text-format-button">
                                            <span className="format-title">H<sub>1</sub></span> Heading 1
                                        </button>
                                        <button onClick={() => addTextBox('Heading 2', 18)} className="text-format-button">
                                            <span className="format-title">H<sub>2</sub></span> Heading 2
                                        </button>
                                        <button onClick={() => addTextBox('Body', 14)} className="text-format-button">
                                            <span className="format-title">Aa</span> Body
                                        </button>
                                    </div>
                                    <h2>Elements</h2>
                                    <input
                                        type="text"
                                        className="search-input"
                                        placeholder="Search elements..."
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </>
                            ) : (
                                <>
                                    {selectedElement && selectedElement.type !== 'text' ? (
                                        <>
                                            <h3>Edit Chart</h3>
                                            <button
                                                onClick={() => generateChartCommentary(selectedElement)} className="generate-commentary-button"
                                            >
                                                Generate Chart Commentary
                                            </button>
                                        </>
                                    ) : (
                                        <h3>Edit Text</h3>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="sidebar-content">
                            {showAvailableElements ? (
                                <div className="edit-elements-list">
                                    {filteredElements.map((element) => (
                                        <div
                                            key={element.id}
                                            className="available-element"
                                            onClick={() => handleAddElement(element)}
                                        >
                                            <Visualization config={element.chart_config} />
                                            <p className="element-name">{element.name}</p>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="edit-element-form">
                                    {renderDynamicControls()}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* Schedule Refresh Modal */}
                <ReactModal
                    isOpen={isScheduleModalOpen}
                    onRequestClose={() => setScheduleModalOpen(false)}
                    className="schedule-refresh-modal"
                    overlayClassName="schedule-refresh-overlay"
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                >
                    <h2>Schedule Dashboard Refresh</h2>
                    <div className="day-selection">
                        <label>Select Days of the Week:</label>
                        <div className="days-of-week">
                            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day, index) => (
                                <label key={index}>
                                    <input
                                        type="checkbox"
                                        value={day}
                                        checked={selectedDays.includes(day)}
                                        onChange={(e) => handleDaySelection(e.target.value)}
                                    />
                                    {day}
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="time-input">
                        <label>Select Time:</label>
                        <input
                            type="text"
                            placeholder="e.g., 12:00PM"
                            value={timeInput}
                            onChange={(e) => setTimeInput(e.target.value)}
                        />
                        {timeError && <p style={{ color: 'red' }}>{timeError}</p>}
                    </div>
                    <div className="repeat-selection">
                        <label>Repeat:</label>
                        <select
                            value={repeatOption}
                            onChange={(e) => setRepeatOption(e.target.value)}
                        >
                            <option value="none">None</option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                        </select>
                    </div>
                    <div className="refresh-modal-buttons">
                        <button onClick={() => setScheduleModalOpen(false)} className="refresh-schedule-cancel-button">
                            Cancel
                        </button>
                        <button onClick={handleScheduleRefresh} className="set-schedule-button">
                            Set Schedule
                        </button>
                    </div>
                </ReactModal>

                <ReactModal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    className="unsaved-changes-modal"
                    overlayClassName="unsaved-changes-overlay"
                    ariaHideApp={false}
                >
                    <h2>You have unsaved changes</h2>
                    <div className="modal-buttons">
                        <button onClick={handleSaveDashboard} className="save-changes-button">Save Changes</button>
                        <button onClick={handleExitWithoutSaving} className="exit-without-saving-button">Exit Without Saving</button>
                    </div>
                </ReactModal>
            </div>
        </DndProvider>
    );
};

export default EditDashboard;
