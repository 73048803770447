import React from 'react';
import { Table } from 'antd';

const TableVisualization = ({ config }) => {
    if (!config || !config.columns || !config.dataSource) {
        console.warn("No valid table config provided.");
        return null;
    }

    const { columns, dataSource, pagination, loading, bordered, scroll, showHeader, size, onChange } = config;

    // Enhance columns with proper sorter and filtering logic
    const enhancedColumns = columns.map(col => ({
        ...col,
        sorter: col.sorter
            ? (a, b) => {
                  const valueA = a[col.dataIndex];
                  const valueB = b[col.dataIndex];

                  if (typeof valueA === 'number' && typeof valueB === 'number') {
                      return valueA - valueB; // Sort numerically
                  }

                  if (typeof valueA === 'string' && typeof valueB === 'string') {
                      return valueA.localeCompare(valueB); // Sort alphabetically
                  }

                  // Add more checks if needed (e.g., for dates)
                  return 0;
              }
            : false,
        filters: col.filters ? col.filters.map(filter => ({
            text: filter.text || filter.value,
            value: filter.value
        })) : [],
        onFilter: (value, record) => {
            const recordValue = record[col.dataIndex];
            return typeof recordValue === 'string' ? recordValue.includes(value) : recordValue === value;
        },
    }));

    // Generate a unique row key by combining multiple fields or using an index
    const generateRowKey = (record, index) => {
        // Example: Combine Department and Gender to ensure unique row keys
        return `${record.Department}-${record.Gender}-${index}`;
    };

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
            <Table
                columns={enhancedColumns}
                dataSource={dataSource}
                pagination={pagination}
                loading={loading}
                bordered={bordered}
                rowKey={generateRowKey} // Set the unique rowKey function
                scroll={scroll}
                showHeader={showHeader}
                size={size}
                onChange={onChange}
            />
        </div>
    );
};

export default TableVisualization;
