import { Link } from 'react-router-dom';
import './Settings.css';

const Settings = () => {
    return (
        <div className="settings-page">
            <h1>Settings</h1>

            <div className="settings-section">
                <h2>User Access</h2>
                {/* Remove this extra paragraph element */}
                {/* <p>View and manage user role and access</p> */}
                <Link to="/settings/user-access" className="settings-link">
                    View and manage user access
                    <img src="/icons/arrow-back.svg" alt="Arrow Right" className="arrow-right-icon" />
                </Link>
            </div>

            <div className="settings-section">
                <h2>Archived Chats</h2>
                {/* Remove this extra paragraph element */}
                {/* <p>View and manage archived chats</p> */}
                <Link to="/settings/archived-chats" className="settings-link">
                    View and manage archived chats
                    <img src="/icons/arrow-back.svg" alt="Arrow Right" className="arrow-right-icon" />
                </Link>
            </div>
        </div>
    );
};

export default Settings;