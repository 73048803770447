import React from 'react';
import './DataSourceCard.css';

const DataSourceCard = ({ name, icon, buttonText, openModal }) => {
  return (
    <div className="data-source-card">
        <img src={icon} alt={`${name} icon`} className="data-source-icon" style={{width: '70px', height: '70px'}}/>
        <h3>{name}</h3>
        <button className="add-button" onClick={openModal}>{buttonText}</button>
    </div>
  );
};

export default DataSourceCard;
