import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../fetchWithCsrf'; // Import the configured Axios instance
import { auth } from '../firebaseConfig'; // Import Firebase Auth
import { useAuth } from '../AuthContext'; // Import useAuth hook
import API_BASE_URL from "../config";

const Logout = () => {
    const navigate = useNavigate();
    const { setAuth } = useAuth(); // Use the useAuth hook

    useEffect(() => {
        const logout = async () => {
            try {
                // Send the logout request to the backend
                await axios.post(`${API_BASE_URL}/logout/`, {}, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                });

                // Sign out from Firebase Auth
                await auth.signOut();

                // Clear the authentication state
                setAuth(false);

                navigate('/login'); // Redirect to the login page
            } catch (error) {
                console.error('Logout error:', error);
            }
        };
        logout();
    }, [setAuth, navigate]);

    return null;
};

export default Logout;
