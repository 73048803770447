import React, { useEffect, useState, useRef } from 'react';
import { Table, Tag, Input, Avatar, Dropdown, Menu, Button, Modal, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import './UserAccessPage.css';
import API_BASE_URL from "../config";

const UserAccessPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const searchInput = useRef(null);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [email, setEmail] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/users/`);
            setUsers(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching users:", error);
            setLoading(false);
        }
    };

    const handleDeleteUser = (userId) => {
        console.log(`Delete user with ID: ${userId}`);
    };

    const handleReassignRole = (userId, currentRole) => {
        const newRole = currentRole === 'Admin' ? 'User' : 'Admin';
        console.log(`Reassign user with ID: ${userId} to role: ${newRole}`);
    };

    const handleInvite = async () => {
        if (!email) {
            message.error('Please enter an email address');
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/invite-user/`, { email });
            message.success('User invited successfully!');
            setIsModalVisible(false);
            setEmail(''); // Clear the input field
            fetchUsers(); // Refresh the user list
        } catch (error) {
            console.error("Error inviting user:", error);
            message.error(error.response?.data?.error || 'Error inviting user');
        }
    };

    const menu = (record) => (
        <Menu>
            <Menu.Item key="1" onClick={() => handleDeleteUser(record.id)}>
                Delete User
            </Menu.Item>
            <Menu.Item key="2" onClick={() => handleReassignRole(record.id, record.role)}>
                {record.role === 'Admin' ? 'Reassign to User' : 'Reassign to Admin'}
            </Menu.Item>
        </Menu>
    );

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => {
                    if (searchInput.current) {
                        searchInput.current.select();
                    }
                }, 100);
            }
        },
        render: text => text,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={record.avatar} style={{ marginRight: 8 }}>{text[0]}</Avatar>
                    {text}
                </div>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (role) => {
                let color = role === 'Admin' ? 'yellow' : role === 'Analyst' ? 'blue' : 'green';
                return <Tag color={color}>{role}</Tag>;
            },
        },
        {
            title: 'Joined',
            dataIndex: 'joined',
            key: 'joined',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Dropdown overlay={menu(record)} trigger={['click']}>
                    <Button type="text" icon={<EllipsisOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <div className="user-access-page">
            <div className="data-sources-back-button-container">
                <button onClick={() => navigate('/settings')} className="data-sources-back-button">
                    <img src="/icons/arrow-back.svg" alt="Back" className="data-sources-back-arrow"/> Back
                </button>
            </div>
            <div className="user-access-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <h1>User Access</h1>
                <Button
                    type="primary"
                    onClick={() => setIsModalVisible(true)}
                    style={{ marginTop: 50 }} // Add top margin here
                >
                    Invite User
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={users}
                loading={loading}
                rowKey={(record) => record.id}
                className="user-access-table"
            />
            <Modal
                title="Invite User"
                open={isModalVisible}
                onOk={handleInvite}
                onCancel={() => setIsModalVisible(false)}
                okText="Invite"
                cancelText="Cancel"
            >
                <Input
                    placeholder="Enter user email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Modal>
        </div>
    );
};

export default UserAccessPage;
