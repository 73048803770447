import React, {useState, useEffect, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../fetchWithCsrf';
import Visualization from '../components/Visualization';
import ReactModal from 'react-modal'; // Import ReactModal
import './EditElement.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PptxGenJS from 'pptxgenjs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import API_BASE_URL from "../config";

const EditElement = () => {
    const { id } = useParams();
    const [element, setElement] = useState(null);
    const [config, setConfig] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [changesSaved, setChangesSaved] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
    const [navigationTarget, setNavigationTarget] = useState(null);
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState({ barColor: false, borderColor: false });
    const [selectedChartType, setSelectedChartType] = useState('');
    const [userQuery, setUserQuery] = useState(''); // For user query input
    const [reconfiguredMessage, setReconfiguredMessage] = useState(null); // Store the returned message
    const [isShareDropdownOpen, setIsShareDropdownOpen] = useState(false);
    const shareDropdownRef = useRef(null);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/get-element/${id}/`, {
            withCredentials: true,
        })
        .then(response => {
            console.log('Fetched Element Data:', response.data); // Log the raw fetched data

            let fetchedConfig = response.data.chart_config;
            console.log('Original Chart Config:', fetchedConfig); // Log the original chart configuration

            // Determine the initial chart type
            const initialChartType = mapConfigToChartType(fetchedConfig);
            console.log('Determined Initial Chart Type:', initialChartType); // Log the determined chart type
            setSelectedChartType(initialChartType);

            // If the chart type is 'Bar Line Combo', set first dataset as 'line' and others as 'bar'
            if (initialChartType === 'Bar Line Combo') {
                console.log('Applying dataset type override for Bar Line Combo.');
                const updatedDatasets = fetchedConfig.data.datasets.map((dataset, index) => ({
                    ...dataset,
                    type: index === 0 ? 'line' : 'bar', // First as line, rest as bar
                }));
                console.log('Updated Datasets:', updatedDatasets); // Log the updated datasets

                fetchedConfig = {
                    ...fetchedConfig,
                    data: {
                        ...fetchedConfig.data,
                        datasets: updatedDatasets,
                    },
                };
                console.log('Modified Chart Config after Override:', fetchedConfig); // Log the modified chart config
            } else {
                console.log('No dataset type override applied.');
            }

            setElement(response.data);
            setConfig(fetchedConfig);
        })
        .catch(error => {
            console.error('Error fetching element:', error);
        });
    }, [id]);

    useEffect(() => {
        // Function to handle click outside dropdown
        const handleClickOutside = (event) => {
            if (shareDropdownRef.current && !shareDropdownRef.current.contains(event.target)) {
                setIsShareDropdownOpen(false);
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [shareDropdownRef]);


    const mapConfigToChartType = (config) => {
        if (config?.data?.datasets) {
            const types = config.data.datasets.map(dataset => dataset.type || config.type);
            const hasBar = types.includes('bar');
            const hasLine = types.includes('line');

            if (hasBar && hasLine) return 'Bar Line Combo';
        }

        if (config?.type === 'line' && config?.isArea) return 'Area';
        if (config?.type === 'line') return 'Line';
        if (config?.type === 'bar' && config?.options?.scales?.x?.stacked) return 'Stacked Bar';
        if (config?.type === 'bar') return 'Bar';
        if (config?.type === 'pie') return 'Pie';

        return '';
    };


    const handleSaveChanges = () => {
        axios.post(`${API_BASE_URL}/edit-element/${id}/`, { chart_config: config }, {
            withCredentials: true,
        })
        .then(() => {
            setHasUnsavedChanges(false);
            setChangesSaved(true);  // Show "Changes Saved" message
            setTimeout(() => {
                setChangesSaved(false);  // Hide after 4 seconds
            }, 4000);
            if (navigationTarget) {
                navigate(navigationTarget);
            }
        })
        .catch(error => console.error('Error saving changes:', error));
    };

    const updateChartConfig = (field, value) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            [field]: value,
        }));
        setHasUnsavedChanges(true);
    };

    const updateChartOptions = (field, value) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            options: {
                ...prevConfig.options,
                [field]: value,
            },
        }));
        setHasUnsavedChanges(true);
    };


    const handleNavigationAway = (target) => {
        if (hasUnsavedChanges) {
            setNavigationTarget(target);
            setIsModalOpen(true);  // Open the modal
        } else {
            navigate(target);
        }
    };

    const handleExitWithoutSaving = () => {
        setIsModalOpen(false);
        setHasUnsavedChanges(false);
        if (navigationTarget) {
            navigate(navigationTarget);
        }
    };

    const handleChartTypeChange = async (newType) => {
        let isArea = false;
        let isStacked = false;
        let chartType = '';
        let datasets = [...config.data.datasets];

        if (newType === 'Area') {
            chartType = 'line';
            isArea = true;
        } else if (newType === 'Line') {
            chartType = 'line';
        } else if (newType === 'Bar') {
            chartType = 'bar';
        } else if (newType === 'Stacked Bar') {
            chartType = 'bar';
            isStacked = true;
        } else if (newType === 'Pie') {
            chartType = 'pie';
        } else if (newType === 'Bar Line Combo') {
            chartType = 'bar';
            datasets = datasets.map((dataset, index) => ({
                ...dataset,
                type: index === 0 ? 'line' : 'bar',
            }));
        } else if (newType === 'Table') {  // Add logic for "Table" chart type
        chartType = 'table';  // Send "table" as the chart type
        }
        console.log('Datasets after setting for Bar Line Combo:', datasets);


        try {
            console.log("Sending request to modify chart with chart type:", chartType);

            const response = await axios.post(`${API_BASE_URL}/modify-chart/`, {
                chartType,
                sqlQuery: element.sql_query,
                queryResults: element.data,
                chartConfig: {
                    ...config,
                    type: chartType,
                    data: {
                        ...config.data,
                        datasets,
                    },
                    isArea,
                    options: {
                        ...config.options,
                        scales: {
                            ...config.options.scales,
                            x: {
                                ...config.options.scales?.x,
                                stacked: isStacked,
                            },
                            y: {
                                ...config.options.scales?.y,
                                stacked: isStacked,
                            },
                        },
                    },
                },
            }, {
                withCredentials: true,
            });

            const newConfig = response.data;
            console.log("Received new chart config from backend:", newConfig);

            // Update config and selectedChartType after API call succeeds
            setConfig({ ...newConfig, isArea });
            setSelectedChartType(newType);
            setHasUnsavedChanges(true);
        } catch (error) {
            console.error('Error generating new chart config:', error);
        }
    };

    const ensureArrayLength = (arr, length, defaultValue = '#000000') => {
        if (!Array.isArray(arr)) {
            return Array(length).fill(defaultValue);
        }
        if (arr.length < length) {
            return [...arr, ...Array(length - arr.length).fill(arr[0] || defaultValue)];
        }
        return arr;
    };

    const handleDatasetTypeChange = (datasetIndex, newType) => {
        setConfig((prevConfig) => {
            const newDatasets = prevConfig.data.datasets.map((dataset, index) => {
                if (index === datasetIndex) {
                    return {
                        ...dataset,
                        type: newType, // Update the dataset type
                    };
                }
                return dataset;
            });

            return {
                ...prevConfig,
                data: {
                    ...prevConfig.data,
                    datasets: newDatasets,
                },
            };
        });
    };

    const prepareDatasets = (config) => {
        if (!config || !config.data || !config.data.datasets) return config;

        const updatedConfig = {
            ...config,
            data: {
                ...config.data,
                datasets: config.data.datasets.map((dataset, index) => ({
                    ...dataset,
                    // Set dataset type to 'line' for the first dataset and 'bar' for others
                    type: dataset.type || (index === 0 ? 'line' : 'bar'),
                    order: dataset.type === 'line' ? 1 : 2,  // Lines drawn on top

                    // Only set pointBackgroundColor and borderColor for line datasets
                    ...(dataset.type === 'line' && {
                        pointBackgroundColor: ensureArrayLength(dataset.pointBackgroundColor, dataset.data.length, '#000000'),
                        borderColor: ensureArrayLength(dataset.borderColor, dataset.data.length, '#000000'),
                    }),

                    // For bar datasets, ensure backgroundColor and borderColor arrays are initialized
                    ...(dataset.type === 'bar' && {
                        backgroundColor: ensureArrayLength(dataset.backgroundColor, dataset.data.length, dataset.backgroundColor ? dataset.backgroundColor[0] : '#36a2eb'),
                        borderColor: ensureArrayLength(dataset.borderColor, dataset.data.length, dataset.borderColor ? dataset.borderColor[0] : '#000000'),
                    }),

                })),
            },
        };

        return updatedConfig;
    };


    const renderDynamicControls = () => {
        if (!config || !config.data || !config.data.datasets) return null;

        // Determine applicable chart types based on the data structure
        const variableCount = config.data.labels?.length || 0;
        const datasetCount = config.data.datasets?.length || 0;

        // Filter chart types based on the dataset structure
        let availableChartTypes = [];

        if (variableCount > 1 && datasetCount === 1) {
                availableChartTypes.push('Pie');
        }

        if (variableCount > 1) {
                availableChartTypes.push('Bar', 'Line', 'Area');
        }

        if (datasetCount > 1) {
                    availableChartTypes.push('Stacked Bar', 'Bar Line Combo');
        }

        // Function to render color controls based on chart type
        const renderColorControls = () => {
            return config.data.datasets.map((dataset, datasetIndex) => {
                // Check if the chart is an area chart (line chart with fill)
                const chartType = selectedChartType.toLowerCase().replace(/\s+/g, '');
                const datasetLabel = `Dataset ${datasetIndex + 1}`; // Label for each dataset section


                // Apply fallback logic to ensure color arrays are correctly populated
                // Debug: Log the dataset and chartType to see what's being passed
                console.log(`Rendering Dataset ${datasetIndex}`, dataset);
                console.log('Chart Type:', chartType);

                // Ensure color arrays are initialized properly
                // Ensure color arrays are initialized properly
                const borderColor = ensureArrayLength(dataset?.borderColor || [], dataset?.data?.length || 0, '#000000');
                const backgroundColor = ensureArrayLength(dataset?.backgroundColor || ['#ffffff'], dataset.data.length, '#ffffff');

                // Only apply pointBackgroundColor and pointBorderColor if dataset type is 'line'
                let pointBackgroundColor = [];
                let pointBorderColor = [];

                if (dataset.type === 'line') {
                    pointBackgroundColor = ensureArrayLength(dataset?.pointBackgroundColor || borderColor, dataset?.data?.length || 0, '#000000');
                    pointBorderColor = ensureArrayLength(dataset?.pointBorderColor || borderColor, dataset?.data?.length || 0, '#000000');
                    console.log('pointBackgroundColor (line chart):', pointBackgroundColor);
                    console.log('pointBorderColor (line chart):', pointBorderColor);
                }

                switch (chartType) {
                            case 'bar':
                            case 'stackedbar':
                                // Bar and Stacked Bar Charts
                                return (
                                    <div key={datasetIndex}>
                                        <h3>{datasetLabel}</h3>

                                        {/* General Bar Color */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Bar Color:
                                                <input
                                                    type="color"
                                                    value={backgroundColor[0]} // Using the first color from the array as the general color
                                                    onChange={(e) => handleGeneralColorChange('backgroundColor', e.target.value, datasetIndex)}
                                                    className="color-input"
                                                />
                                            </label>
                                            {/* Individual Bar Color Dropdown with Icon */}
                                            <button onClick={() => toggleDropdown(`barColor-${datasetIndex}`)} className="color-dropdown-button">
                                                <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                            </button>
                                        </div>
                                        {dropdownOpen[`barColor-${datasetIndex}`] && (
                                            <div className="color-picker-dropdown">
                                                {backgroundColor.map((color, colorIndex) => (
                                                    <div key={colorIndex}>
                                                        <label>
                                                            Bar {colorIndex + 1}:
                                                            <input
                                                                type="color"
                                                                value={color}
                                                                onChange={(e) => handleSpecificColorChange('backgroundColor', colorIndex, e.target.value, datasetIndex)}
                                                                className="color-input"
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {/* General Bar Border Color */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Bar Border Color:
                                                <input
                                                    type="color"
                                                    value={borderColor[0]} // Using the first color from the array as the general border color
                                                    onChange={(e) => handleGeneralColorChange('borderColor', e.target.value, datasetIndex)}
                                                    className="color-input"
                                                />
                                            </label>
                                            {/* Individual Bar Border Color Dropdown with Icon */}
                                            <button onClick={() => toggleDropdown(`barBorderColor-${datasetIndex}`)} className="color-dropdown-button">
                                                <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                            </button>
                                        </div>
                                        {dropdownOpen[`barBorderColor-${datasetIndex}`] && (
                                            <div className="color-picker-dropdown">
                                                {borderColor.map((color, colorIndex) => (
                                                    <div key={colorIndex}>
                                                        <label>
                                                            Bar Border {colorIndex + 1}:
                                                            <input
                                                                type="color"
                                                                value={color}
                                                                onChange={(e) => handleSpecificColorChange('borderColor', colorIndex, e.target.value, datasetIndex)}
                                                                className="color-input"
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                );

                            case 'line':
                                // Line Charts
                                return (
                                    <div key={datasetIndex}>
                                        <h3>{datasetLabel}</h3>

                                        {/* General Line Color */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Line Color:
                                                <input
                                                    type="color"
                                                    value={borderColor[0] || '#000000'}  // Line color should use borderColor, not backgroundColor
                                                    onChange={(e) => handleGeneralColorChange('borderColor', e.target.value, datasetIndex)}
                                                    className="color-input"
                                                />
                                            </label>
                                        </div>

                                        {/* Individual Line Colors Dropdown */}
                                        {dropdownOpen[`lineColor-${datasetIndex}`] && (
                                            <div className="color-picker-dropdown">
                                                {borderColor.map((color, colorIndex) => (
                                                    <div key={colorIndex}>
                                                        <label>
                                                            Line {colorIndex + 1}:
                                                            <input
                                                                type="color"
                                                                value={color}
                                                                onChange={(e) => handleSpecificColorChange('borderColor', colorIndex, e.target.value, datasetIndex)}
                                                                className="color-input"
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {/* General Dot Color */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Dot Color:
                                                <input
                                                    type="color"
                                                    value={pointBackgroundColor[0] || '#ffffff'}  // Dot color for line charts
                                                    onChange={(e) => handleGeneralColorChange('pointBackgroundColor', e.target.value, datasetIndex)}
                                                    className="color-input"
                                                />
                                            </label>
                                            <button onClick={() => toggleDropdown(`dotColor-${datasetIndex}`)} className="color-dropdown-button">
                                                <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                            </button>
                                        </div>

                                        {/* Individual Dot Colors Dropdown */}
                                        {dropdownOpen[`dotColor-${datasetIndex}`] && (
                                            <div className="color-picker-dropdown">
                                                {pointBackgroundColor.map((color, colorIndex) => (
                                                    <div key={colorIndex}>
                                                        <label>
                                                            Dot {colorIndex + 1}:
                                                            <input
                                                                type="color"
                                                                value={color}
                                                                onChange={(e) => handleSpecificColorChange('pointBackgroundColor', colorIndex, e.target.value, datasetIndex)}
                                                                className="color-input"
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {/* General Dot Border Color */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Dot Border Color:
                                                <input
                                                    type="color"
                                                    value={pointBorderColor[datasetIndex] || '#000000'}  // Dot border color for line charts
                                                    onChange={(e) => handleGeneralColorChange('pointBorderColor', e.target.value, datasetIndex)}
                                                    className="color-input"
                                                />
                                            </label>
                                            <button onClick={() => toggleDropdown(`dotBorderColor-${datasetIndex}`)} className="color-dropdown-button">
                                                <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                            </button>
                                        </div>

                                        {/* Individual Dot Border Colors Dropdown */}
                                        {dropdownOpen[`dotBorderColor-${datasetIndex}`] && (
                                            <div className="color-picker-dropdown">
                                                {pointBorderColor.map((color, colorIndex) => (
                                                    <div key={colorIndex}>
                                                        <label>
                                                            Dot Border {colorIndex + 1}:
                                                            <input
                                                                type="color"
                                                                value={color}
                                                                onChange={(e) => handleSpecificColorChange('pointBorderColor', colorIndex, e.target.value, datasetIndex)}
                                                                className="color-input"
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                );

                            case 'area':
                                // Area Charts (similar to line but includes fill color)
                                return (
                                    <div key={datasetIndex}>
                                        <h3>{datasetLabel}</h3>

                                        {/* General Line Color */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Line Color:
                                                <input
                                                    type="color"
                                                    value={borderColor[0]}
                                                    onChange={(e) => handleGeneralColorChange('borderColor', e.target.value, datasetIndex)}
                                                    className="color-input"
                                                />
                                            </label>
                                        </div>

                                         {/* General Dot Color */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Dot Color:
                                                <input
                                                    type="color"
                                                    value={pointBackgroundColor[0]}
                                                    onChange={(e) => handleGeneralColorChange('pointBackgroundColor', e.target.value, datasetIndex)}
                                                    className="color-input"
                                                />
                                            </label>
                                            <button onClick={() => toggleDropdown(`dotColor-${datasetIndex}`)} className="color-dropdown-button">
                                                <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                            </button>
                                        </div>
                                        {dropdownOpen[`dotColor-${datasetIndex}`] && (
                                            <div className="color-picker-dropdown">
                                                {pointBackgroundColor.map((color, colorIndex) => (
                                                    <div key={colorIndex}>
                                                        <label>
                                                            Dot {colorIndex + 1}:
                                                            <input
                                                                type="color"
                                                                value={color}
                                                                onChange={(e) => handleSpecificColorChange('pointBackgroundColor', colorIndex, e.target.value, datasetIndex)}
                                                                className="color-input"
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        {/* General Fill Color */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Fill Color:
                                                <input
                                                    type="color"
                                                    value={backgroundColor[0]}
                                                    onChange={(e) => handleGeneralColorChange('backgroundColor', e.target.value, datasetIndex)}
                                                    className="color-input"
                                                />
                                            </label>
                                        </div>

                                        {/* General Dot Border Color for Area Chart */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Dot Border Color:
                                                <input
                                                    type="color"
                                                    value={pointBorderColor[0]}
                                                    onChange={(e) => handleGeneralColorChange('pointBorderColor', e.target.value, datasetIndex)}
                                                    className="color-input"
                                                />
                                            </label>
                                            <button onClick={() => toggleDropdown(`dotBorderColor-${datasetIndex}`)} className="color-dropdown-button">
                                                <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                            </button>
                                        </div>
                                        {dropdownOpen[`dotBorderColor-${datasetIndex}`] && (
                                            <div className="color-picker-dropdown">
                                                {pointBorderColor.map((color, colorIndex) => (
                                                    <div key={colorIndex}>
                                                        <label>
                                                            Dot Border {colorIndex + 1}:
                                                            <input
                                                                type="color"
                                                                value={color}
                                                                onChange={(e) => handleSpecificColorChange('pointBorderColor', colorIndex, e.target.value, datasetIndex)}
                                                                className="color-input"
                                                            />
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                );

                            case 'pie':
                                // Pie Charts
                                return (
                                    <div key={datasetIndex}>
                                      <h3>{datasetLabel}</h3>

                                      {/* General Slice Color */}
                                      <div className="color-picker-row">
                                        <label className="color-input-container">
                                          Slice Color:
                                          <input
                                            type="color"
                                            value={backgroundColor[0]} // General color for the first slice
                                            onChange={(e) => handleGeneralColorChange('backgroundColor', e.target.value, datasetIndex)}
                                            className="color-input"
                                          />
                                        </label>
                                        <button onClick={() => toggleDropdown(`sliceColor-${datasetIndex}`)} className="color-dropdown-button">
                                          <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                        </button>
                                      </div>

                                      {/* Individual Slice Colors Dropdown */}
                                      {dropdownOpen[`sliceColor-${datasetIndex}`] && (
                                        <div className="color-picker-dropdown">
                                          {backgroundColor.map((color, colorIndex) => (
                                            <div key={colorIndex}>
                                              <label>
                                                Slice {colorIndex + 1}:
                                                <input
                                                  type="color"
                                                  value={color}
                                                  onChange={(e) => handleSpecificColorChange('backgroundColor', colorIndex, e.target.value, datasetIndex)}
                                                  className="color-input"
                                                />
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      )}

                                      {/* General Slice Border Color */}
                                      <div className="color-picker-row">
                                        <label className="color-input-container">
                                          Slice Border Color:
                                          <input
                                            type="color"
                                            value={borderColor[0]} // General border color for the first slice
                                            onChange={(e) => handleGeneralColorChange('borderColor', e.target.value, datasetIndex)}
                                            className="color-input"
                                          />
                                        </label>
                                        <button onClick={() => toggleDropdown(`sliceBorderColor-${datasetIndex}`)} className="color-dropdown-button">
                                          <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                        </button>
                                      </div>

                                      {/* Individual Slice Border Colors Dropdown */}
                                      {dropdownOpen[`sliceBorderColor-${datasetIndex}`] && (
                                        <div className="color-picker-dropdown">
                                          {borderColor.map((color, colorIndex) => (
                                            <div key={colorIndex}>
                                              <label>
                                                Slice Border {colorIndex + 1}:
                                                <input
                                                  type="color"
                                                  value={color}
                                                  onChange={(e) => handleSpecificColorChange('borderColor', colorIndex, e.target.value, datasetIndex)}
                                                  className="color-input"
                                                />
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                );

                            case 'barlinecombo':
                                // Bar Line Combo Chart
                                return (
                                    <div key={datasetIndex}>
                                        <h3>{datasetLabel}</h3>
                                        {/* Force first dataset to be line, and others as bar only if type is not already set */}
                                        {!dataset.type && (datasetIndex === 0 ? (dataset.type = 'line') : (dataset.type = 'bar'))}
                                        {/* Dataset Type Control */}
                                        <div className="color-picker-row">
                                            <label className="color-input-container">
                                                Dataset Type:
                                                <select
                                                    value={dataset.type}
                                                    onChange={(e) => handleDatasetTypeChange(datasetIndex, e.target.value)}
                                                    className="dataset-type-select"
                                                >
                                                    <option value="bar">Bar</option>
                                                    <option value="line">Line</option>
                                                </select>
                                            </label>
                                        </div>

                                        {/* Show bar or line controls based on selected dataset type */}
                                        {dataset.type === 'bar' ? (
                                            <>
                                                {/* Bar Controls */}
                                                <div className="color-picker-row">
                                                    <label className="color-input-container">
                                                        Bar Color:
                                                        <input
                                                            type="color"
                                                            value={backgroundColor[0]}
                                                            onChange={(e) => handleGeneralColorChange('backgroundColor', e.target.value, datasetIndex)}
                                                            className="color-input"
                                                        />
                                                    </label>
                                                    {/* Individual Bar Color Dropdown with Icon */}
                                                    <button onClick={() => toggleDropdown(`barColor-${datasetIndex}`)} className="color-dropdown-button">
                                                        <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                                    </button>
                                                </div>
                                                {dropdownOpen[`barColor-${datasetIndex}`] && (
                                                    <div className="color-picker-dropdown">
                                                        {backgroundColor.map((color, colorIndex) => (
                                                            <div key={colorIndex}>
                                                                <label>
                                                                    Bar {colorIndex + 1}:
                                                                    <input
                                                                        type="color"
                                                                        value={color}
                                                                        onChange={(e) => handleSpecificColorChange('backgroundColor', colorIndex, e.target.value, datasetIndex)}
                                                                        className="color-input"
                                                                    />
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                                {/* Bar Border Color */}
                                                <div className="color-picker-row">
                                                    <label className="color-input-container">
                                                        Bar Border Color:
                                                        <input
                                                            type="color"
                                                            value={borderColor[0]}
                                                            onChange={(e) => handleGeneralColorChange('borderColor', e.target.value, datasetIndex)}
                                                            className="color-input"
                                                        />
                                                    </label>
                                                    {/* Individual Bar Border Color Dropdown with Icon */}
                                                    <button onClick={() => toggleDropdown(`barBorderColor-${datasetIndex}`)} className="color-dropdown-button">
                                                        <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                                    </button>
                                                </div>
                                                {dropdownOpen[`barBorderColor-${datasetIndex}`] && (
                                                    <div className="color-picker-dropdown">
                                                        {borderColor.map((color, colorIndex) => (
                                                            <div key={colorIndex}>
                                                                <label>
                                                                    Bar Border {colorIndex + 1}:
                                                                    <input
                                                                        type="color"
                                                                        value={color}
                                                                        onChange={(e) => handleSpecificColorChange('borderColor', colorIndex, e.target.value, datasetIndex)}
                                                                        className="color-input"
                                                                    />
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {/* General Line Color */}
                                                <div className="color-picker-row">
                                                    <label className="color-input-container">
                                                        Line Color:
                                                        <input
                                                            type="color"
                                                            value={borderColor[datasetIndex] || '#000000'}  // Line color should use borderColor, not backgroundColor
                                                            onChange={(e) => handleGeneralColorChange('borderColor', e.target.value, datasetIndex)}
                                                            className="color-input"
                                                        />
                                                    </label>
                                                </div>

                                                {/* Individual Line Colors Dropdown */}
                                                {dropdownOpen[`lineColor-${datasetIndex}`] && (
                                                    <div className="color-picker-dropdown">
                                                        {borderColor.map((color, colorIndex) => (
                                                            <div key={colorIndex}>
                                                                <label>
                                                                    Line {colorIndex + 1}:
                                                                    <input
                                                                        type="color"
                                                                        value={color}
                                                                        onChange={(e) => handleSpecificColorChange('borderColor', colorIndex, e.target.value, datasetIndex)}
                                                                        className="color-input"
                                                                    />
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                                {/* General Dot Color */}
                                                <div className="color-picker-row">
                                                    <label className="color-input-container">
                                                        Dot Color:
                                                        <input
                                                            type="color"
                                                            value={pointBackgroundColor[datasetIndex] || '#000000'}  // Dot color for line charts
                                                            onChange={(e) => handleGeneralColorChange('pointBackgroundColor', e.target.value, datasetIndex)}
                                                            className="color-input"
                                                        />
                                                    </label>
                                                    <button onClick={() => toggleDropdown(`dotColor-${datasetIndex}`)} className="color-dropdown-button">
                                                        <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                                    </button>
                                                </div>

                                                {/* Individual Dot Colors Dropdown */}
                                                {dropdownOpen[`dotColor-${datasetIndex}`] && (
                                                    <div className="color-picker-dropdown">
                                                        {pointBackgroundColor.map((color, colorIndex) => (
                                                            <div key={colorIndex}>
                                                                <label>
                                                                    Dot {colorIndex + 1}:
                                                                    <input
                                                                        type="color"
                                                                        value={color}
                                                                        onChange={(e) => handleSpecificColorChange('pointBackgroundColor', colorIndex, e.target.value, datasetIndex)}
                                                                        className="color-input"
                                                                    />
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                                {/* General Dot Border Color */}
                                                <div className="color-picker-row">
                                                    <label className="color-input-container">
                                                        Dot Border Color:
                                                        <input
                                                            type="color"
                                                            value={pointBorderColor[datasetIndex] || '#000000'}  // Dot border color for line charts
                                                            onChange={(e) => handleGeneralColorChange('pointBorderColor', e.target.value, datasetIndex)}
                                                            className="color-input"
                                                        />
                                                    </label>
                                                    <button onClick={() => toggleDropdown(`dotBorderColor-${datasetIndex}`)} className="color-dropdown-button">
                                                        <img src="/icons/arrow-back.svg" alt="Dropdown Arrow" className="dropdown-arrow" />
                                                    </button>
                                                </div>

                                                {/* Individual Dot Border Colors Dropdown */}
                                                {dropdownOpen[`dotBorderColor-${datasetIndex}`] && (
                                                    <div className="color-picker-dropdown">
                                                        {pointBorderColor.map((color, colorIndex) => (
                                                            <div key={colorIndex}>
                                                                <label>
                                                                    Dot Border {colorIndex + 1}:
                                                                    <input
                                                                        type="color"
                                                                        value={color}
                                                                        onChange={(e) => handleSpecificColorChange('pointBorderColor', colorIndex, e.target.value, datasetIndex)}
                                                                        className="color-input"
                                                                    />
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                );

                            default:
                                return null;
                        }
                    });
                };

        return (
            <>
                <label>
                    Chart Type:
                    <select
                        value={selectedChartType}
                        onChange={(e) => handleChartTypeChange(e.target.value)}
                    >
                        {availableChartTypes.map((chartType) => (
                            <option key={chartType} value={chartType}>
                                {chartType}
                            </option>
                        ))}
                    </select>
                </label>

                {/* Additional controls for title and axis labels */}
                <label>
                    Chart Title:
                    <input
                        type="text"
                        value={config.options?.plugins?.title?.text || ''}
                        onChange={(e) => updateChartOptions('plugins', {
                            ...config.options?.plugins,
                            title: {
                                ...config.options?.plugins?.title,
                                text: e.target.value,
                            },
                        })}
                    />
                </label>
                <label>
                    X-Axis Label:
                    <input
                        type="text"
                        value={config.options?.scales?.x?.title?.text || ''}
                        onChange={(e) => updateChartOptions('scales', {
                            ...config.options?.scales,
                            x: {
                                ...config.options?.scales?.x,
                                title: {
                                    ...config.options?.scales?.x?.title,
                                    text: e.target.value,
                                },
                            },
                        })}
                    />
                </label>
                <label>
                    Y-Axis Label:
                    <input
                        type="text"
                        value={config.options?.scales?.y?.title?.text || ''}
                        onChange={(e) => updateChartOptions('scales', {
                            ...config.options?.scales,
                            y: {
                                ...config.options?.scales?.y,
                                title: {
                                    ...config.options?.scales?.y?.title,
                                    text: e.target.value,
                                },
                            },
                        })}
                    />
                </label>

                <label>
                    Legend:
                    <select
                        value={config.options?.plugins?.legend?.display ? 'yes' : 'no'}
                        onChange={(e) => {
                            updateChartOptions('plugins', {
                                ...config.options?.plugins,
                                legend: {
                                    ...config.options?.plugins?.legend,
                                    display: e.target.value === 'yes',
                                },
                            });
                        }}
                    >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </label>


                {/* Data Labels Control */}
                <label>
                    Data Labels:
                    <select
                        value={config.showDataLabels ? 'yes' : 'no'}
                        onChange={(e) => updateChartConfig('showDataLabels', e.target.value === 'yes')}
                    >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </label>

                {config.showDataLabels && (
                    <label>
                        Data Label Offset:
                        <input
                            type="number"
                            value={config.dataLabelOffset || 0}
                            onChange={(e) => updateChartConfig('dataLabelOffset', Number(e.target.value))}
                        />
                    </label>
                )}

                {/* Render color controls based on selected chart type */}
                {renderColorControls()}
            </>
        );
    };


    const toggleDropdown = (type) => {
        setDropdownOpen((prev) => ({
            ...prev,
            [type]: !prev[type],  // Toggle the specific dropdown type (barColor or borderColor)
        }));
    };

    const handleGeneralColorChange = (field, value, datasetIndex) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            data: {
                ...prevConfig.data,
                datasets: prevConfig.data.datasets.map((dataset, index) =>
                    index === datasetIndex
                        ? {
                            ...dataset,
                            [field]: Array(prevConfig.data.labels.length).fill(value) // Apply the color to all bars/dots in the targeted dataset
                          }
                        : dataset // Keep other datasets unchanged
                ),
            },
        }));
        setHasUnsavedChanges(true);
    };

    const handleSpecificColorChange = (field, colorIndex, value, datasetIndex) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            data: {
                ...prevConfig.data,
                datasets: prevConfig.data.datasets.map((dataset, index) => {
                    if (index !== datasetIndex) return dataset; // If not the target dataset, return it unchanged

                    // Ensure the field exists and is an array
                    const updatedField = Array.isArray(dataset[field])
                        ? dataset[field]
                        : ensureArrayLength([], dataset.data.length, '#000000'); // Initialize if undefined

                    // Apply the color change to the specific color index
                    return {
                        ...dataset,
                        [field]: updatedField.map((color, i) => (i === colorIndex ? value : color)),
                    };
                }),
            },
        }));
        setHasUnsavedChanges(true);
    };


    const handleReconfigureChart = async () => {
        if (!userQuery) return;

        try {
            const response = await axios.post(`${API_BASE_URL}/fetch_data/`, {
                user_input: userQuery,
                chat_id: element.id, // Assuming each chart element has a unique ID
                conversation_history: element.conversation_history,
                chart_config: config,
                sql_query: element.sql_query,
                queryResults: element.data,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            const { cleaned_output, chart_config, message_id, data } = response.data;

            const newSystemMessage = {
                from_user: 'system',
                text: cleaned_output || "No response from GPT.",
                messageId: message_id,
                data,
                chartConfig: chart_config,
                sqlQuery: response.data.sql_query,
            };

            if (chart_config && Object.keys(chart_config).length > 0) {
                newSystemMessage.type = 'chart';
                newSystemMessage.cleanedOutput = cleaned_output;
            }

            setConfig(chart_config); // Update chart config with the new one from backend
            setReconfiguredMessage(newSystemMessage); // Store the new message
            setHasUnsavedChanges(true);
        } catch (error) {
            console.error('Error reconfiguring chart:', error);
        }
    };


    const handleExportToPDF = () => {
        const input = document.querySelector('.visualization-container'); // Ensure this matches your visualization container

        html2canvas(input, { scale: 2 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save(`${element.name || 'element'}.pdf`);
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };

    const handleExportToPPTX = () => {
        const pptx = new PptxGenJS();
        const slide = pptx.addSlide();

        const input = document.querySelector('.visualization-container');

        html2canvas(input, { scale: 2 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');

                slide.addImage({
                    data: imgData,
                    x: 0,
                    y: 0,
                    w: pptx.width,
                    h: (canvas.height * pptx.width) / canvas.width,
                });

                pptx.writeFile({ fileName: `${element.name || 'element'}.pptx` });
            })
            .catch((error) => {
                console.error('Error generating PPTX:', error);
            });
    };

    const handleExportDataToCSV = () => {
      const data = element.data;
      console.log('Raw data:', data);

      if (!data || data.length === 0) {
        alert('No data available to export.');
        return;
      }

      // Flatten data if it's an array of arrays
      let dataArray;
      if (Array.isArray(data)) {
        dataArray = data.flat();
      } else {
        dataArray = Object.values(data);
      }
      console.log('Data array:', dataArray);

      if (dataArray.length === 0) {
        alert('No data available to export.');
        return;
      }

      // Ensure dataArray[0] is an object
      if (typeof dataArray[0] !== 'object' || dataArray[0] === null) {
        alert('Data format is not supported.');
        return;
      }

      // Extract headers from the first object
      const headers = Object.keys(dataArray[0]);
      console.log('Headers:', headers);

      const csvRows = [headers.join(',')]; // Add headers as the first row
      console.log('CSV Rows after adding headers:', csvRows);

      // Loop through the data and add each row
      dataArray.forEach((row, index) => {
        const values = headers.map((header) => `"${row[header] || ''}"`);
        csvRows.push(values.join(','));
        console.log(`CSV Rows after processing row ${index}:`, csvRows);
      });

      // Create the CSV string
      const csvString = csvRows.join('\n');
      console.log('Final CSV string:', csvString);

      // Trigger CSV download
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'data_export.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleExportDataToExcel = () => {
      const data = element.data;
      console.log('Raw data:', data);

      if (!data || data.length === 0) {
        alert('No data available to export.');
        return;
      }

      // Flatten data if it's an array of arrays
      let dataArray;
      if (Array.isArray(data)) {
        dataArray = data.flat();
      } else {
        dataArray = Object.values(data);
      }
      console.log('Data array:', dataArray);

      if (dataArray.length === 0) {
        alert('No data available to export.');
        return;
      }

      // Ensure dataArray[0] is an object
      if (typeof dataArray[0] !== 'object' || dataArray[0] === null) {
        alert('Data format is not supported.');
        return;
      }

      // Extract headers from the first object
      const headers = Object.keys(dataArray[0]);
      console.log('Headers:', headers);

      // Create worksheet data with headers as the first row
      const worksheetData = [headers];
      console.log('Worksheet data after adding headers:', worksheetData);

      // Loop through the data and add each row
      dataArray.forEach((row, index) => {
        const values = headers.map((header) => row[header] || '');
        worksheetData.push(values);
        console.log(`Worksheet data after processing row ${index}:`, worksheetData);
      });

      // Create worksheet and workbook
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

      // Generate Excel file and trigger download
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, 'data_export.xlsx');
    };







    const handleShareViaEmail = () => {
        const subject = encodeURIComponent(`Sharing Element: ${element.name}`);
        const body = encodeURIComponent(`Hi,\n\nI wanted to share this element with you: ${element.name}.\n\nBest regards,\n[Your Name]`);

        window.location.href = `mailto:?subject=${subject}&body=${body}`;
    };




    if (!element) return <div>Loading...</div>;

    return (
        <div className="edit-element">
            <div className="edit-element-back-button-container">
                <button onClick={() => handleNavigationAway('/dashboards')} className="edit-element-back-button">
                    <img src="/icons/arrow-back.svg" alt="Back" className="edit-element-back-arrow"/> Back
                </button>
            </div>
            <div className="edit-element-header">
                <h1>{element.name}</h1>
                {hasUnsavedChanges && !changesSaved && (
                    <span className="unsaved-changes-indicator">Unsaved changes</span>
                )}
                {changesSaved && (
                    <span className="saved-changes-indicator-element">Changes Saved</span>
                )}
                <button onClick={handleSaveChanges} className="save-changes-button">Save Changes</button>
                {/* Dropdown Share Button */}
                <div className="share-dropdown-container" ref={shareDropdownRef}>
                    <button
                        className="share-dropdown-button"
                        onClick={() => setIsShareDropdownOpen(!isShareDropdownOpen)}
                    >
                        Share/Export
                    </button>
                    {isShareDropdownOpen && (
                        <ul className="share-dropdown-menu">
                            <li onClick={handleShareViaEmail}>Share via Email</li>
                            <li onClick={handleExportToPDF}>Export to PDF</li>
                            <li onClick={handleExportToPPTX}>Export to PowerPoint</li>
                            <li onClick={handleExportDataToExcel}>Export Data to Excel</li>
                            <li onClick={handleExportDataToCSV}>Export Data to CSV</li>
                        </ul>
                    )}
                </div>
            </div>
            <div className="edit-element-container">
                <div className="visualization-container">
                    <Visualization config={selectedChartType === 'Bar Line Combo' ? prepareDatasets(config) : config}
                    requestedChartType={selectedChartType}
                    />
                </div>

                <div className="edit-element-sidebar">
                    {renderDynamicControls()}
                </div>
            </div>

            {/* Reconfigure Chart Styled Like Chat Interface */}
            <div className="chat-interface-reconfigure-interface">
                <div className="chat-window-reconfigure-window">
                    {reconfiguredMessage && (
                        <div className="chat-message-gpt-message">
                            {reconfiguredMessage.text}
                        </div>
                    )}
                </div>

                <div className="chat-input-section-edit-element">
                    <input
                        type="text"
                        value={userQuery}
                        onChange={(e) => setUserQuery(e.target.value)}
                        placeholder="E.g. Change time range to last 6 months"
                        onKeyPress={(e) => e.key === 'Enter' && handleReconfigureChart()}
                        className="reconfigure-query-input"
                    />
                    <button onClick={handleReconfigureChart}>Reconfigure Chart</button>
                </div>
            </div>

            {/* Modal for unsaved changes */}
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                className="unsaved-changes-modal"
                overlayClassName="unsaved-changes-overlay"
                ariaHideApp={false}
            >
                <h2>You have unsaved changes</h2>
                <div className="modal-buttons">
                    <button onClick={handleSaveChanges} className="save-changes-button">Save Changes</button>
                    <button onClick={handleExitWithoutSaving} className="exit-without-saving-button">Exit Without Saving</button>
                </div>
            </ReactModal>
        </div>
    );
};

export default EditElement;
