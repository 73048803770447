import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PasswordChange.css';
import axios from '../fetchWithCsrf'; // Import the configured Axios instance
import { auth } from '../firebaseConfig'; // Import Firebase Auth
import API_BASE_URL from "../config";

const PasswordChange = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }
        try {
            // Get the current user's ID token for authentication
            const user = auth.currentUser;
            if (user) {
                const idToken = await user.getIdToken();

                console.log('Sending password change request'); // Debug statement
                const body = { old_password: oldPassword, new_password1: newPassword, new_password2: confirmPassword };
                console.log('Request Body:', body); // Debug statement

                // Send the password change request
                const response = await axios.post(`${API_BASE_URL}/password_change/`, body, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${idToken}`, // Include ID token in the authorization header
                    },
                    withCredentials: true, // Ensure cookies are included in the request
                });

                console.log('Raw response:', response); // Debug statement
                if (response.status === 200) {
                    navigate('/login');
                } else {
                    setError(response.data.error);
                }
            } else {
                setError('User not authenticated');
            }
        } catch (error) {
            console.error('Password change error:', error);
            setError('Failed to change password.');
        }
    };

    return (
        <div className="password-change-container">
            <form className="password-change-form" onSubmit={handleSubmit}>
                <h2>Change Password</h2>
                {error && <div className="error-message">{error}</div>}
                <div className="input-group">
                    <label htmlFor="oldPassword">Old Password</label>
                    <input
                        type="password"
                        id="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Enter your old password"
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter your new password"
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="confirmPassword">Confirm New Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm your new password"
                        required
                    />
                </div>
                <button type="submit" className="password-change-button">Change Password</button>
            </form>
        </div>
    );
};

export default PasswordChange;
