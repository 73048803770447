import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'; // Ensure you have the correct imports from Firebase
import './ForgotPassword.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const auth = getAuth(); // Initialize Firebase Auth

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent. Please check your inbox.');
            setError('');
        } catch (error) {
            setMessage('');
            setError('Failed to send password reset email. Please try again.');
            console.error('Password reset error:', error);
        }
    };

    return (
        <div className="forgot-password-container">
            <div className="logo">
                <img src="/icons/LogoNoBackground.png" alt="Spruce Logo" />
                <h1>Spruce</h1>
            </div>
            <form className="forgot-password-form" onSubmit={handleSubmit}>
                <h2>Forgot Password</h2>
                {message && <div className="success-message">{message}</div>}
                {error && <div className="error-message">{error}</div>}
                <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <button type="submit" className="forgot-password-button">Send Reset Email</button>
                <div className="signup-link">
                    Remembered your password? <a href="/login">Sign in</a>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;
