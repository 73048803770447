import React, { useState, useEffect } from 'react';
import axios from '../fetchWithCsrf'; // Adjust the path according to your project structure
import Sidebar from '../components/Sidebar';
import ChatInterface from '../components/ChatInterface';
import './Home.css';
import API_BASE_URL from "../config";

const Home = () => {
    const [chats, setChats] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [unsavedChatId, setUnsavedChatId] = useState(null); // To track the unsaved chat ID
    const [savingMessage, setSavingMessage] = useState(null);
    const [showArchiveModal, setShowArchiveModal] = useState(false); // State for archive modal

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/chats/`, {
                    withCredentials: true  // Ensure cookies are sent with the request
                });
                let fetchedChats = response.data;
                fetchedChats = fetchedChats.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setChats(fetchedChats);
                if (fetchedChats.length > 0) {
                    const firstChatId = fetchedChats[0].id;
                    setCurrentChat(firstChatId);
                    fetchChatContent(firstChatId);
                } else {
                    createNewChat();
                }
            } catch (error) {
                console.error('Error fetching chats:', error);
                createNewChat();
            }
        };


        fetchChats();
    }, []);

    const fetchChatContent = async (chatId) => {
        if (chatId === unsavedChatId) {
            setMessages([]);
            return;
        }
        try {
            const response = await axios.get(`${API_BASE_URL}/chats/${chatId}/messages/`, {
                withCredentials: true
            });

            const processedMessages = response.data.map((msg) => {
                console.log('Message from backend:', msg);

                // Determine 'from_user' as 'user' or 'system'
                const fromUser = msg.from_user === true || msg.from_user === 'user' ? 'user' : 'system';

                if (msg.chart_config) {
                    return {
                        ...msg,
                        type: 'chart',
                        cleanedOutput: msg.text,
                        chartConfig: msg.chart_config,
                        from_user: fromUser,
                        sqlQuery: msg.sql_query,
                        data: msg.data,
                        databaseId: msg.database_id,
                        messageId: msg.message_id || msg.id,
                    };
                }

                return {
                    ...msg,
                    from_user: fromUser,
                };
            });

            const sortedMessages = processedMessages.sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at)
            );

            setMessages(sortedMessages);
        } catch (error) {
            console.error('Error fetching chat content:', error);
        }
    };


    const createNewChat = () => {
        const temporaryChat = { id: `temp_${Date.now()}`, title: 'New Chat' };
        setChats(prevChats => [temporaryChat, ...prevChats]);
        setCurrentChat(temporaryChat.id);
        setUnsavedChatId(temporaryChat.id); // Track this as the unsaved chat
        setMessages([]);
    };

    const saveChat = async (title) => {
        const newChat = { title };
        try {
            const response = await axios.post(`${API_BASE_URL}/chats/`, newChat, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
            const addedChat = response.data;

            setChats(prevChats => prevChats.map(chat =>
                chat.id === unsavedChatId ? addedChat : chat
            ));
            setCurrentChat(addedChat.id);
            setUnsavedChatId(null); // Clear the unsaved chat ID
            return addedChat;
        } catch (error) {
            console.error('Error saving chat:', error.response?.data || error.message);
        }
    };

    const sendMessage = async (chatId, newMessage) => {
        if (!chatId || chatId === unsavedChatId) {
            const savedChat = await saveChat(newMessage.text.slice(0, 20) + '...');
            chatId = savedChat.id;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/fetch_data/`, {
                user_input: newMessage.text,
                chat_id: chatId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });

            console.log('API Response:', response.data);  // Logging the full API response to inspect

            const { cleaned_output, chart_config, message_id, data, database_id } = response.data;  // Extract message_id from the response

            const newSystemMessage = {
                from_user: 'system',
                text: cleaned_output || "No response from GPT.",
                messageId: message_id,  // Always include messageId
                data: data,  // Include the data here
                chartConfig: chart_config,
                sqlQuery: response.data.sql_query,  // Include SQL query
                database_id: database_id
            };

            if (chart_config && Object.keys(chart_config).length > 0) {
                newSystemMessage.type = 'chart';
                newSystemMessage.cleanedOutput = cleaned_output;
                newSystemMessage.chartConfig = chart_config;
                newSystemMessage.messageId = message_id;  // Include messageId for the chart
            }

            setMessages(prevMessages => [...prevMessages, newMessage, newSystemMessage]);

            setSavingMessage(newSystemMessage);

            setSavingMessage(newSystemMessage);  // Update savingMessage with the chart data and messageId

        } catch (error) {
            console.error('Error sending message:', error.response ? error.response.data : error.message);
        }
    };

    const handleChatSelect = (chatId) => {
        setCurrentChat(chatId);
        fetchChatContent(chatId);
    };

    const deleteChat = async (index) => {
        const chatToDelete = chats[index];
        const updatedChats = chats.filter((_, i) => i !== index);

        // Optimistically update the chat list in the frontend
        setChats(updatedChats);

        // Only delete if the chat has been saved (i.e., has an ID)
        if (chatToDelete.id && chatToDelete.id !== unsavedChatId) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/chats/${chatToDelete.id}/`, {
                    withCredentials: true
                });

                // Check if the chat was archived instead of deleted
                if (response.data.status === "Chat archived instead of deleted because it contains saved elements") {
                    console.log("Chat was archived because it has saved elements:", response.data.message);
                    setShowArchiveModal(true);  // Show the archive modal if the chat was archived

                    // Select the next available chat, or set to null if no chats remain
                    if (updatedChats.length > 0) {
                        const nextChat = updatedChats[0]; // Select the next chat in the list
                        setCurrentChat(nextChat.id);
                        fetchChatContent(nextChat.id); // Fetch the content of the next chat
                    } else {
                        setCurrentChat(null);  // No chats left, go blank
                        setMessages([]);       // Clear the chat messages
                    }
                }
            } catch (error) {
                console.error('Error deleting chat:', error);
                setChats(chats);  // Revert back to the original chat list
            }
        }
    };


    return (
        <div className="home">
            <Sidebar
                chats={chats}
                currentChat={currentChat}
                addChat={createNewChat}
                deleteChat={deleteChat}
                setCurrentChat={handleChatSelect}
                showArchiveModal={showArchiveModal} // Pass the modal state as props
                setShowArchiveModal={setShowArchiveModal} // Pass the modal state setter as props
            />
            {currentChat && (
                <ChatInterface
                    currentChat={currentChat}
                    messages={messages}
                    updateChat={sendMessage}
                />
            )}
        </div>
    );
}

export default Home;
