import axios from 'axios';
import API_BASE_URL from "./config";

axios.defaults.withCredentials = true;

// Fetch CSRF token initially
const getCsrfToken = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-csrf-token/`);
        // Set the CSRF token as a default header for future requests
        axios.defaults.headers.common['X-CSRFToken'] = response.data.csrfToken;
        console.log('CSRF token set:', response.data.csrfToken);
    } catch (error) {
        console.error('CSRF token error:', error);
    }
};

// Fetch CSRF token once on initialization
getCsrfToken();

// Re-export configured axios instance
export default axios;
