import React, { useState, useEffect, useRef } from 'react';
import axios from '../fetchWithCsrf';
import Visualization from './Visualization';
import './ChatInterface.css';
import API_BASE_URL from '../config';

const ChatInterface = ({ currentChat, messages, updateChat }) => {
    const [userInput, setUserInput] = useState('');
    const [localMessages, setLocalMessages] = useState(messages);
    const [loading, setLoading] = useState(false);
    const [isNamingModalOpen, setIsNamingModalOpen] = useState(false);
    const [isDataModalOpen, setIsDataModalOpen] = useState(false);
    const [elementName, setElementName] = useState('');
    const [savingMessage, setSavingMessage] = useState(null);
    const [viewingMessage, setViewingMessage] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [databases, setDatabases] = useState({});
    const [isChartTypeDropdownOpen, setIsChartTypeDropdownOpen] = useState(false); // New state for dropdown visibility
    const chatWindowRef = useRef(null);
    const dropdownRef = useRef(null); // Define dropdownRef here
    const [chartLoadingStates, setChartLoadingStates] = useState({});


    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [localMessages]);

    useEffect(() => {
        setLocalMessages(messages);
        setLoading(false);
    }, [messages]);

    // Close dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsChartTypeDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);


    useEffect(() => {
        const fetchDatabases = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/datasources/`, {
                    withCredentials: true
                });
                const dbMap = {};
                response.data.forEach(db => {
                    dbMap[db.id] = db.name;
                });
                setDatabases(dbMap);
            } catch (error) {
                console.error('Error fetching databases:', error);
            }
        };

        fetchDatabases();
    }, []);


    useEffect(() => {
        if (savingMessage && savingMessage.messageId) {
            console.log("Message is ready for saving:", savingMessage);
        } else {
            console.log("Waiting for messageId to be assigned...");
        }
    }, [savingMessage]);


    const handleSend = () => {
        if (currentChat && userInput.trim()) {
            const newMessage = { from_user: 'user', text: userInput };

            setLocalMessages((prevMessages) => [...prevMessages, newMessage]);
            setLoading(true);

            updateChat(currentChat, newMessage);

            setUserInput('');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSend();
        }
    };

    const openNamingModal = (msg) => {
        if (!msg?.messageId) {
            console.error("Message ID is missing or undefined.", msg);  // Add a console log to ensure that messageId is present.
            return;
        }

        const messageToSave = {
            ...msg,
            messageId: msg.messageId,  // Ensure this exists and is passed
        };
        setSavingMessage(messageToSave);
        setIsNamingModalOpen(true);
    };



    const closeNamingModal = () => {
        setIsNamingModalOpen(false);
        setElementName('');
        setIsSuccess(false);
    };

    const openDataModal = (msg) => {
        console.log("Opening data modal with message:", msg);

        // Check if the data is available and correctly formatted
        if (!msg?.data || !Array.isArray(msg.data) || msg.data.length === 0) {
            console.error("No data available in this message or data format is incorrect:", msg);
            return;
        }

        // If msg.data exists and it's an array, proceed to flatten the structure
        if (msg.data[0] && Array.isArray(msg.data[0])) {
            console.log("Data available for display:", msg.data[0]);
            setViewingMessage({
                ...msg,
                data: msg.data[0],  // Flatten the nested structure
            });
            setIsDataModalOpen(true);
        } else {
            console.error("Unexpected data structure:", msg.data);
        }
    };


    const closeDataModal = () => {
        setIsDataModalOpen(false);
        setViewingMessage(null);
    };

    const handleSaveElement = async () => {
        if (!savingMessage?.messageId) {
            alert("Message ID is missing, please wait or try again.");
            console.error("Message ID is missing:", savingMessage);  // Add a log to track missing messageId
            return;
        }

        const newElement = {
            element_type: 'graph',
            name: elementName,
            chart_config: savingMessage.chartConfig,
            message_id: savingMessage.messageId
        };

        try {
            console.log('New element payload:', newElement);  // Log the payload to debug before saving
            await axios.post(`${API_BASE_URL}/save-element/`, newElement, {
                withCredentials: true,
            });
            setIsSuccess(true);
        } catch (error) {
            console.error('Error saving element:', error.response?.data || error.message);
        }
    };



    // Handles the API call and updates the chart configuration based on the selected chart type
    const handleChartTypeChange = async (messageIndex, newType) => {
        const msg = localMessages[messageIndex];
        if (!msg) {
            console.error("Message not found at index:", messageIndex);
            return;
        }


        const { chartConfig, sqlQuery, data } = msg;

        try {
            setChartLoadingStates(prevStates => ({ ...prevStates, [messageIndex]: true }));
            console.log("Sending request to modify chart with chart type:", newType);
            console.log("Current SQL Query:", sqlQuery);
            console.log("Current Query Results:", data);

            // Send the request to modify the chart
            const response = await axios.post(
                `${API_BASE_URL}/modify-chart/`,
                {
                    chartType: newType,
                    sqlQuery: sqlQuery,
                    queryResults: data,
                    chartConfig: {
                        ...chartConfig,
                    },
                },
                {
                    withCredentials: true,
                }
            );

            const newConfig = response.data;
            console.log("Received new chart config from backend:", newConfig);

            // Update the specific message's chartConfig in localMessages and set the selectedChartType
            const updatedMessages = [...localMessages];
            updatedMessages[messageIndex] = {
                ...msg,
                chartConfig: newConfig,
                selectedChartType: newType,  // Store the user's selection
            };
            setLocalMessages(updatedMessages);

            setIsChartTypeDropdownOpen(false);  // Close the dropdown after selection

        } catch (error) {
            console.error('Error generating new chart config:', error.response?.data || error.message);
            alert('Failed to update chart type. Please try again.');
        } finally {
            // Set loading state to false for this message
            setChartLoadingStates(prevStates => ({ ...prevStates, [messageIndex]: false }));
        }
    };


    const renderMessage = (msg, index) => {
        if (msg.chartConfig) {
            let availableChartTypes = ['Table']; // Always include "Table" as an option
            let variableCount = 0;
            let datasetCount = 0;

            // Check if it's a Table chart
            if (msg.chartConfig.type === 'table') {
                const { dataSource, columns } = msg.chartConfig;

                // Infer variable count from columns and dataset count from dataSource
                if (columns && Array.isArray(columns)) {
                    variableCount = columns.length;  // Columns determine the number of variables
                }

                if (dataSource && Array.isArray(dataSource)) {
                    datasetCount = dataSource.length; // Rows determine the number of datasets
                }

                // Log inferred values for debugging
                console.log('Inferred Variable Count (Columns):', variableCount);
                console.log('Inferred Dataset Count (Rows):', datasetCount);

                if (variableCount > 1) {
                    availableChartTypes.push('Bar', 'Line', 'Area', 'Pie');
                }

                if (variableCount > 2) {
                    availableChartTypes.push('Stacked Bar', 'Bar Line Combo');
                }
            } else {
                // Use chartConfig's labels and datasets for non-table charts
                variableCount = msg.chartConfig.data?.labels?.length || 0;
                datasetCount = msg.chartConfig.data?.datasets?.length || 0;

                // Dynamically determine available chart types based on dataset structure
                if (variableCount > 1 && datasetCount === 1) {
                    availableChartTypes.push('Pie');
                }

                if (variableCount > 1) {
                    availableChartTypes.push('Bar', 'Line', 'Area');
                }

                if (datasetCount > 1) {
                    availableChartTypes.push('Stacked Bar', 'Bar Line Combo');
                }
            }

            // Log available chart types
            console.log('Available chart types:', availableChartTypes);
            // Check if this message is currently loading
            const isChartLoading = chartLoadingStates[index];
             // Determine if "Table" is the only available chart type
            const isOnlyTable = availableChartTypes.length === 1 && availableChartTypes[0] === 'Table';

            return (
                <div key={index} className="chat-message chart-message">
                    <div className="message-text gpt-message">
                        {msg.cleanedOutput || "No description available."}
                    </div>

                    <div className="message-chart">
                        {isChartLoading ? (
                            // Display the loading GIF
                            <img
                                src="/icons/Logo-loading.gif"
                                alt="Loading..."
                                className="loading-gif"
                            />
                        ) : (
                            // Pass the selected chart type as a prop to Visualization
                            <Visualization
                                config={msg.chartConfig}
                                requestedChartType={msg.selectedChartType}  // Use the user's selection
                            />
                        )}
                    </div>

                    <div className="chart-options-container">
                        <button onClick={() => openNamingModal(msg)} className="save-element-button">
                            Save as Element
                        </button>
                        <button onClick={() => openDataModal(msg)} className="view-data-button">
                            View Data
                        </button>

                        {/* Conditionally render the Chart Type Dropdown */}
                        {!isOnlyTable && (
                            <div className="chart-type-container" ref={dropdownRef}>
                                <button
                                    className="chart-type-button"
                                    onClick={() => setIsChartTypeDropdownOpen(prev => !prev)}
                                    aria-haspopup="true"
                                    aria-expanded={isChartTypeDropdownOpen}
                                >
                                    Chart Type &#9662;
                                </button>
                                {isChartTypeDropdownOpen && (
                                    <ul className="dropdown-menu">
                                        {availableChartTypes.map(chartType => (
                                            <li
                                                key={chartType}
                                                role="menuitem"
                                                onClick={() => handleChartTypeChange(index, chartType)}
                                            >
                                                {chartType}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div key={index} className={`chat-message ${msg.from_user === 'user' ? 'user-message' : 'gpt-message'}`}>
                {msg.text || "No content available."}
            </div>
        );
    };


    return (
        <div className="chat-interface">
            {currentChat ? (
                <div className="chat-window" ref={chatWindowRef}>
                    {localMessages && localMessages.map((msg, index) => renderMessage(msg, index))}
                    {loading && (
                        <div className="chat-message gpt-message loading">
                            <img src="/icons/Logo-loading.gif" alt="Loading..."/>
                        </div>
                    )}
                </div>
            ) : (
                <div className="no-chat-selected">Select or start a new chat to begin</div>
            )}
            <div className="chat-input-section">
                <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Type your message..."
                    onKeyPress={handleKeyPress}
                />
                <button onClick={handleSend}>Send</button>
            </div>

            {isNamingModalOpen && (
                <div className="modal-overlay">
                    <div className="rename-modal-content">
                        {isSuccess ? (
                            <div>
                                <h2>Success</h2>
                                <p>Chart saved as element successfully!</p>
                                <button
                                    onClick={closeNamingModal}
                                    className="close-button-top-right"
                                    style={{marginTop: '20px'}}
                                >
                                    Close
                                </button>
                            </div>
                        ) : (
                            <div>
                                <h2>Name This Element</h2>
                                <input
                                    type="text"
                                    value={elementName}
                                    onChange={(e) => setElementName(e.target.value)}
                                />
                                <div className="modal-buttons">
                                    <button onClick={closeNamingModal} className="cancel-button">Cancel</button>
                                    <button onClick={handleSaveElement} className="confirm-rename-button">Save</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {isDataModalOpen && viewingMessage && (
                <div className="modal-overlay">
                    <div className="modal-content modal-content-scrollable">
                        <h3 className="left-justify">Database</h3>
                        <p className="left-justify">{databases[viewingMessage.database_id] || 'Unknown Database'}</p>
                        <h3 className="left-justify">SQL Query</h3>
                        <pre className="sql-query">{viewingMessage.sqlQuery || "No query available"}</pre>
                        <h3 className="left-justify">Queried Data</h3>
                        {viewingMessage?.data?.[0] ? (
                            <div className="table-container">   {/* Add scrollable container here */}
                                <table className="table-bordered">
                                    <thead>
                                        <tr>
                                            {Object.keys(viewingMessage.data[0]).map(key => (
                                                <th key={key}>{key}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {viewingMessage.data.map((row, index) => (
                                            <tr key={index}>
                                                {Object.values(row).map((value, idx) => (
                                                    <td key={idx}>{value}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No data available to display.</p>
                        )}

                        {/* Close button at the bottom */}
                        <button
                            onClick={closeDataModal}
                            className="close-button-top-right"
                            style={{ marginTop: '20px' }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatInterface;
