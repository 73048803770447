import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { auth } from './firebaseConfig';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [idToken, setIdToken] = useState(null);

  useEffect(() => {
    console.log('AuthProvider mounted');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await getIdToken(user);
        setIdToken(token);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        setIdToken(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const setAuth = useCallback(async (status) => {
    setIsAuthenticated(status);
    if (status && auth.currentUser) {
      const token = await getIdToken(auth.currentUser);
      setIdToken(token);
    } else {
      setIdToken(null);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, idToken, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
