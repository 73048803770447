import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ isAuthenticated }) => {
    return (
        <div className="navbar">
            <NavLink to="/home" end className="navbar-logo">
                <img src="/icons/Logo.png" alt="Spruce Logo" className="logo" />
            </NavLink>
            {isAuthenticated && (
                <div className="navbar-links">
                    <NavLink to="/home" end className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Home
                    </NavLink>
                    <NavLink to="/dashboards" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Dashboards
                    </NavLink>
                    <NavLink to="/my-data-sources" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Data Sources
                    </NavLink>
                    <NavLink to="/settings" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Settings
                    </NavLink>
                    <NavLink to="/logout" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Logout
                    </NavLink>
                </div>
            )}
        </div>
    );
};

export default Navbar;
