import React, { useState, useEffect, useRef } from 'react';
import axios from '../fetchWithCsrf'; // Ensure correct import of axios with CSRF handling
import './Sidebar.css';
import API_BASE_URL from "../config";

const Sidebar = ({ chats, currentChat, addChat, deleteChat, setCurrentChat, showArchiveModal, setShowArchiveModal }) => {
    const [showModal, setShowModal] = useState(false);
    const [chatToDelete, setChatToDelete] = useState(null);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [manualArchive, setManualArchive] = useState(false); // New state to track manual archive
    const dropdownRef = useRef(null); // Ref for the dropdown

    // Handles click to delete chat
    const handleDeleteClick = (index) => {
        const chat = chats[index];

        console.log('Deleting chat:', chat); // DEBUGGING LOG

        // Check if the chat has any saved elements
        if (chat.has_saved_elements) {
            console.log('Chat has saved elements. Archiving chat...'); // DEBUGGING LOG
            handleArchiveClick(chat.id);
            setShowArchiveModal(true); // Show custom archive message modal
        } else {
            console.log('No saved elements. Proceeding with deletion...'); // DEBUGGING LOG
            setChatToDelete(index);
            setShowModal(true);
        }
    };

    // Confirm deletion of chat
    const confirmDelete = () => {
        if (chatToDelete !== null) {
            const chat = chats[chatToDelete];

            console.log('Confirming delete for chat:', chat); // DEBUGGING LOG

            if (chat.has_saved_elements) {
                console.log('Chat still has saved elements. Archiving instead of deleting.'); // DEBUGGING LOG
                handleArchiveClick(chat.id);
                setShowArchiveModal(true); // Show the archive modal
            } else {
                console.log('No saved elements. Proceeding with final deletion.'); // DEBUGGING LOG
                deleteChat(chatToDelete);
                setShowModal(false);
            }
        }
    };

    // Cancel the deletion
    const cancelDelete = () => {
        setChatToDelete(null);
        setShowModal(false);
    };

    // Archive the chat with API call
    const handleArchiveClick = (chatId) => {
        console.log('Archiving chat with ID:', chatId); // DEBUGGING LOG
        axios.patch(`${API_BASE_URL}/archive-chat/${chatId}/`, {}, {
            withCredentials: true
        })
        .then((response) => {
            console.log('Chat archived successfully:', response.data);

            // Track that this is a manual archive
            setManualArchive(true);

            setShowArchiveModal(true);
        })
        .catch((error) => {
            console.error('Error archiving chat:', error);
        });
    };

    // Close archive modal
    const closeArchiveModal = () => {
        setShowArchiveModal(false);
        setManualArchive(false);  // Reset manual archive state
    };

    // Toggle dropdown visibility for more options
    const toggleDropdown = (index) => {
        setDropdownVisible(dropdownVisible === index ? null : index);
    };

    // Effect to handle clicks outside the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="sidebar">
            <button onClick={addChat} className="new-chat-button">
                New Chat
                <img src="/icons/Plus-icon.png" alt="Plus Icon" className="plus-icon" />
            </button>
            <div className="chats-list">
                {chats.filter(chat => !chat.archived).map((chat, index) => (
                    <div
                        key={chat.id}
                        className={`chat-item ${chat.id === currentChat ? 'current-chat' : ''}`}
                        onClick={() => setCurrentChat(chat.id)}
                    >
                        <span>{chat.title}</span>
                        <button
                            className="more-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown(index);
                            }}
                        >
                            <img
                                src="/icons/More-icon.png"
                                alt="More Options"
                                className="more-icon"
                            />
                        </button>
                        {dropdownVisible === index && (
                            <div className="dropdown-menu" ref={dropdownRef}>
                                <button onClick={() => handleDeleteClick(index)}>Delete</button>
                                <button onClick={() => handleArchiveClick(chat.id)}>Archive</button>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Delete Modal */}
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <p>Delete this chat?</p>
                        <div className="modal-buttons">
                            <button onClick={cancelDelete} className="cancel-delete-button">Cancel</button>
                            <button onClick={confirmDelete} className="confirm-delete-button">Delete</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Archive Modal */}
            {showArchiveModal && (
                <div className="confirm-archive-modal-overlay">
                    <div className="confirm-archive-modal">
                        <p>{manualArchive ? "This chat has been archived." : "This chat has been archived as you have previously saved an element from this chat. You can permanently delete this chat and its elements in Settings."}</p>
                        <div className="confirm-archive-modal-buttons">
                            <button onClick={closeArchiveModal} className="sidebar-close-button">Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Sidebar;
