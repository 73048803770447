import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import axios from '../fetchWithCsrf';
import './MyDataSources.css';
import API_BASE_URL from "../config";

const MyDataSources = () => {
  const [dataSources, setDataSources] = useState([]);
  const [retailerPortals, setRetailerPortals] = useState([]); // New state for retailer portals
  const [sortOrder, setSortOrder] = useState({ field: 'created_at', direction: 'desc' });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [renameId, setRenameId] = useState(null);
  const [newName, setNewName] = useState('');
  const [refreshMessage, setRefreshMessage] = useState('');
  const [rotatingId, setRotatingId] = useState(null);
  const navigate = useNavigate();

  const fetchConnectedSources = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/datasources/`, {
        params: { order_by: sortOrder.field, direction: sortOrder.direction },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setDataSources(response.data);
    } catch (error) {
      console.error('Error fetching connected data sources:', error);
    }
  }, [sortOrder]);

  const fetchRetailerPortals = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/retailerportals/`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setRetailerPortals(response.data); // Set the fetched retailer portals
    } catch (error) {
      console.error('Error fetching retailer portals:', error);
    }
  }, []);

  useEffect(() => {
    fetchConnectedSources();
    fetchRetailerPortals(); // Fetch retailer portals when component mounts
  }, [fetchConnectedSources, fetchRetailerPortals]);

  const handleAddDataSource = () => {
    navigate('/data-sources');
  };

  const handleAddRetailerPortal = () => {
    navigate('/retailer-portals'); // Assuming you have a route for adding retailer portals
  };

  const openConfirmModal = (id) => {
    setDeleteId(id);
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setDeleteId(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/datasources/${deleteId}/`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setDataSources(dataSources.filter(ds => ds.id !== deleteId));
      closeConfirmModal();
    } catch (error) {
      console.error('Error deleting data source', error);
    }
  };

  const openRenameModal = (id, currentName) => {
    setRenameId(id);
    setNewName(currentName);
    setIsRenameModalOpen(true);
  };

  const closeRenameModal = () => {
    setIsRenameModalOpen(false);
    setRenameId(null);
    setNewName('');
  };

  const handleRename = async () => {
    try {
      await axios.patch(`${API_BASE_URL}/datasources/${renameId}/rename/`, {
        name: newName,
      }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setDataSources(dataSources.map(ds => ds.id === renameId ? { ...ds, name: newName } : ds));
      closeRenameModal();
    } catch (error) {
      console.error('Error renaming data source', error);
    }
  };

  const handleSort = (field) => {
    setSortOrder(prevOrder => {
      const direction = prevOrder.field === field && prevOrder.direction === 'asc' ? 'desc' : 'asc';
      return { field, direction };
    });
  };

  const getSortIcon = (field) => {
    if (sortOrder.field === field) {
      return (
        <span>
          <span style={{ color: sortOrder.direction === 'asc' ? 'black' : 'grey' }}>▲</span>
          <span style={{ color: sortOrder.direction === 'desc' ? 'black' : 'grey' }}>▼</span>
        </span>
      );
    }
    return (
      <span>
        <span style={{ color: 'grey' }}>▲</span>
        <span style={{ color: 'grey' }}>▼</span>
      </span>
    );
  };

  const handleRefresh = async (id) => {
    setRotatingId(id);
    try {
      const response = await axios.post(`${API_BASE_URL}/datasources/${id}/refresh/`, {}, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      if (response.data.message === 'Data source updated') {
        setRefreshMessage('Data source updated');
        fetchConnectedSources();
      } else {
        setRefreshMessage('No changes detected');
      }
    } catch (error) {
      console.error('Error refreshing data source', error);
      setRefreshMessage('Error refreshing data source');
    } finally {
      setRotatingId(null);
      setTimeout(() => {
        setRefreshMessage('');
      }, 3000);
    }
  };

  return (
    <div className="my-data-sources-page">
      <h2>My Data Sources</h2>
      <button onClick={handleAddDataSource} className="add-data-source-button">Add Data Source +</button>
      {refreshMessage && <div className="refresh-message">{refreshMessage}</div>}
      {dataSources.length === 0 ? (
        <p>No data sources connected.</p>
      ) : (
        <div className="data-sources-list">
          <div className="data-source-header">
            <div className="header-source hidden">Source</div>
            <div className={`sortable header-type ${sortOrder.field === 'data_source_type' ? 'active' : ''}`} onClick={() => handleSort('data_source_type')}>
              Type {getSortIcon('data_source_type')}
            </div>
            <div className={`sortable header-date ${sortOrder.field === 'created_at' ? 'active' : ''}`} onClick={() => handleSort('created_at')}>
              Date {getSortIcon('created_at')}
            </div>
            <div className="header-actions hidden">Actions</div>
          </div>
          {dataSources.map((source) => (
            <div key={source.id} className="data-source-item">
              <div className="data-source-info">
                <span>{source.name}</span>
                <img
                  src="/icons/rename-icon.png"
                  alt="Rename"
                  className="my-datasources-rename-icon"
                  title="Rename"  // Hover text
                  onClick={() => openRenameModal(source.id, source.name)}
                />
                <img
                  src="/icons/Refresh-icon.png"  // Use a static PNG image here
                  alt="Refresh"
                  className={`refresh-icon ${rotatingId === source.id ? 'rotating' : ''}`}
                  title="Refresh"  // Hover text
                  onClick={() => handleRefresh(source.id)}
                />
              </div>
              <div className="data-source-type">
                <img src={`/icons/${source.data_source_type}-icon.png`} alt={source.data_source_type} className="data-source-icon" />
                {source.data_source_type.charAt(0).toUpperCase() + source.data_source_type.slice(1)}
              </div>
              <div className="data-source-date">{new Date(source.created_at).toLocaleDateString()}</div>
              <div className="data-source-actions">
                <button className="view-button" onClick={() => navigate(`/datasources/${source.id}/schema`)}>View Schema</button>
                <button className="delete-button" onClick={() => openConfirmModal(source.id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Retailer Portals Section */}
      <h2 className="retailer-portals-header">Retailer Portals</h2>
      <button onClick={handleAddRetailerPortal} className="add-data-source-button">Add Retailer Portal +</button>
      {retailerPortals.length === 0 ? null : (
        <div className="data-sources-list">
          {retailerPortals.map((portal) => (
            <div key={portal.id} className="data-source-item">
              <div className="data-source-info">
                <span>{portal.name}</span>
                <img src="/icons/rename-icon.png" alt="Rename" className="my-datasources-rename-icon" />
                <img src="/icons/Refresh-icon.png" alt="Refresh" className="refresh-icon" />
              </div>
              <div className="data-source-type">
                <img src={`/icons/${portal.retailer_type}-icon.png`} alt={portal.retailer_type} className="data-source-icon" />
                {portal.retailer_type.charAt(0).toUpperCase() + portal.retailer_type.slice(1)}
              </div>
              <div className="data-source-date">{new Date(portal.connected_at).toLocaleDateString()}</div>
              <div className="data-source-actions">
                <button className="view-button">View Details</button>
                <button className="delete-button">Delete</button>
              </div>
            </div>
          ))}
        </div>
      )}

      <ReactModal isOpen={isConfirmModalOpen} onRequestClose={closeConfirmModal} className="confirm-modal" overlayClassName="confirm-modal-overlay">
        <h2>Confirm Deletion</h2>
        <p>Are you sure you want to delete this data source?</p>
        <div className="modal-buttons">
          <button onClick={closeConfirmModal} className="cancel-button">Cancel</button>
          <button onClick={handleDelete} className="confirm-delete-button">Delete</button>
        </div>
      </ReactModal>

      <ReactModal isOpen={isRenameModalOpen} onRequestClose={closeRenameModal} className="rename-modal" overlayClassName="confirm-modal-overlay">
        <h2>Rename Data Source</h2>
        <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
        <div className="modal-buttons">
          <button onClick={closeRenameModal} className="cancel-button">Cancel</button>
          <button onClick={handleRename} className="confirm-rename-button">Rename</button>
        </div>
      </ReactModal>
    </div>
  );
};

export default MyDataSources;
